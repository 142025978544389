@import 'assets/styles/variables';

.modal {
  display: block;
  color: $gray-900;
  padding-left: 40px;
  padding-right: 40px;
}

.content {
  box-shadow: 0 10px 0 0 rgba(255, 255, 255, 0.5);
}

.small {
  max-width: 480px;
  margin: 20vh auto 64px;
}

.header {
  display: block;
}

.title {
  color: $modal-title-color;
}

.subtitle {
  margin: 8px 0 0;
  color: $modal-subtitle-color;
}

.footer {
  padding-top: 24px;
  padding-bottom: 32px;
}

.body {
  border-bottom: 1px $modal-divider-color solid;
}

.modal :global(.DateRangeBackdrop) {
  top: -68px;
  bottom: -105px;
}
