@import 'assets/styles/variables';

.infoIcon {
  align-items: center;
  color: $input-icon-color;
  display: flex;
  justify-content: center;
  position: relative;
  margin-right: -4px;
  background: rgba($input-icon-color, 0.1);
  border-radius: 50%;
  content: '';
  height: 25px;
  width: 25px;
  z-index: $z-index-normal;

  &:before {
    z-index: $z-index-normal;
  }
}
