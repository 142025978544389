@import 'assets/styles/responsive';

.title {
  display: flex;
  min-height: 42px;
  padding: 5px 20px;
  align-items: center;
}

.wrapper {
  font-size: 12px;
  margin-bottom: 7px;
  padding: 16px 4px 0;
  background-color: $table-accent-bg;
  position: relative;

  @include media-breakpoint-down(lg) {
    padding-bottom: 24px;
  }
}

.wrapperOdd {
  @extend .wrapper;
  background-color: $table-row-bg;
}

.row {
  margin-left: 3px;
  margin-right: 3px;
}

.row [class^='col-'] {
  padding-left: 4px;
  padding-right: 4px;

  & strong {
    padding-top: 0;
  }
}

.icons {
  position: absolute;
  right: 5px;
  bottom: 32px;

  @media (min-width: 1200px) and (max-width: 1250px) {
    right: 0;
  }

  @include media-breakpoint-down(lg) {
    bottom: 2px;
    left: 12px;
  }
}

.iconsExtended {
  position: absolute;
  right: 8px;
  top: 5px;

  @include media-breakpoint-down(lg) {
    right: auto;
    top: auto;
    bottom: 2px;
    left: 12px;
  }
}

.icon {
  cursor: pointer;
  font-size: 20px;
}

.remove {
  @extend .icon;
  color: $red;
  margin-left: 8px;
}

.clone {
  @extend .icon;
  color: $secondary;
}

.datesWrapper {
  @include media-breakpoint-down(lg) {
    right: 60px;
  }
}

.dates {
  min-width: 248px;
}
