@import 'assets/styles/variables';

.hourglassIcon {
  fill: $red;
  padding-right: 4px;
  height: 12px;
}

.businessName {
  display: flex;
  align-items: center;
}