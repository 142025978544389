@import 'assets/styles/responsive';

$badge-size: 19px;

.item {
  margin-top: 4px;
}

.link {
  margin: 0;
  border: none;
  background: none;
  display: flex;
  font-size: 16px;
  padding: 11px 0;
  line-height: 18px;
  border-radius: 4px;
  align-items: center;
  width: calc(100% - 32px);
  color: $sidebar-link-color;
  position: relative;

  .collapsed & {
    width: 100%;
  }
}

:global(.sidebar-open) .link {
  margin: 0 16px;

  .collapsed & {
    margin: 0;
  }
}

.link:focus,
.link:hover {
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  color: $sidebar-link-hover;
  background: rgba($gray-600, 0.1);
}

.link i {
  margin: 1px 12px 0;
  color: $sidebar-icon-color;
}

.link span {
  display: inline;

  @include media-breakpoint-up(sm) {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    display: inline;

    .collapsed & {
      display: none;
    }
  }
}

:global(.sidebar-open) .link span {
  display: inline;

  .collapsed & {
    display: none;
  }
}

.active {
  font-weight: 500;
  border-radius: 4px;
  color: $sidebar-link-active-color;
  background: $sidebar-link-active-background;
}

.active:focus,
.active:hover {
  color: $sidebar-link-active-color;
  background: $sidebar-link-active-background;
}

.active i {
  color: $sidebar-link-active-color;
}

.badge {
  top: 4px;
  left: 17px;
  color: #fff;
  display: flex;
  font-size: 10px;
  position: absolute;
  width: $badge-size;
  height: $badge-size;
  border-radius: 100%;
  background: $orange;
  align-items: center;
  justify-content: center;
}

.right {
  left: inherit;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}
