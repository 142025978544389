@import 'assets/styles/responsive';

.group {
  list-style: none;
  padding: 18px 0 0;
  position: relative;
  margin: ($sidebar-title-font-size + 7px) 16px 0;
  border-top: 1px $sidebar-group-border-color solid;

  @include media-breakpoint-down(sm) {
    border-top: none;
    margin: 8px 16px 0;
    padding: 8px 0 0;
  }

  :global(.sidebar-open) & {
    margin: 0;
    padding: 0;
    border-color: transparent;
  }
}
