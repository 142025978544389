@import 'assets/styles/responsive';
@import 'assets/styles/variables';

.advanced {
  display: none;
}

.toggler {
  padding: 0;
  border: none;
  color: $secondary;
  display: block;
  font-size: 14px;
  cursor: pointer;
  background: none;
  margin-top: -16px;
  position: relative;
  text-decoration: underline;
  outline: none !important;
  z-index: $z-index-normal;
}

.icon {
  display: inline-block;
  padding-right: 10px;
}

.clear {
  @include media-breakpoint-down(sm) {
    margin-top: 8px;
  }
}
