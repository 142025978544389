@import 'assets/styles/responsive';

.section {
  margin: 0 auto;
  max-width: 700px;

  @include media-breakpoint-down(lg) {
    max-width: 600px;
  }

  @include media-breakpoint-down(md) {
    max-width: initial;
    padding: 0 32px;
  }

  @include media-breakpoint-down(md) {
    max-width: initial;
    padding: 0 8px;
  }
}

.column {
  margin-left: 430px;

  @include media-breakpoint-down(md) {
    margin-left: 0;
  }
}

.container {
  min-height: 600px;

  @include media-breakpoint-down(md) {
    min-height: 575px;
  }

  @include media-breakpoint-down(sm) {
    min-height: 0;
  }
}

.footer {
  margin: 64px 0 0;
  padding: 8px 0 140px;
  border-top: 1px $input-border-color solid;
  text-align: right;
}
