@import 'assets/styles/variables';
@import 'assets/styles/responsive';

.header {
  display: flex;
  max-width: 100%;
  padding: 0 $card-padding;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
}
