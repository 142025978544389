@import 'assets/styles/responsive';

.total {
  min-width: 100px;

  @include media-breakpoint-up(sm) {
    padding: 26px 12px 0 4px;
    line-height: 40px;
  }

  @include media-breakpoint-down(sm) {
    margin: 0 8px 0 auto;
    text-align: right;
  }
}

.row {
  @include media-breakpoint-down(sm) {
    border-bottom: 1px solid white;
    margin-bottom: 8px;
  }
}

.removeRow {
  font-size: 14px;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(sm) {
    margin-bottom: 20px;
  }
}

.removeRowButton {
  color: $white;
}

.input label > span {
  color: $white;
}
