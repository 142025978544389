@import 'assets/styles/variables';
.medium {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
}

.small {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
}

.new {
  color: $red;
  &.organizer {
    color: $gray-light;
  }

  &.provider {
    color: $red;
  }
}

.approved {
  color: $green;
}

.deleted {
  color: $red;
}

.pending {
  color: $red;
  &.organizer {
    color: $red;
  }
  &.provider {
    color: $gray-light;
  }
}

.changes_requested {
  color: $gray-light;
  &.organizer {
    color: $gray-light;
  }
  &.provider {
    color: $red;
  }
}

.changes_not_made_in_time {
  color: $red;
  &.organizer {
    color: $red;
  }
  &.provider {
    color: $gray-light;
  }
}

.pending_after_changes_requested {
  color: $red;
  &.organizer {
    color: $red;
  }
  &.provider {
    color: $gray-light;
  }
}

.locked {
  color: $gray-light;
}
