@import 'assets/styles/variables';

$content-width: 500px;
$background-shadow-content: 1px 0px 25px 0px rgba(0, 0, 0, 0.4);

$animation-translation-time: 500ms;
$animation-elements-time: 0;

.sidebar {
  top: 0;
  bottom: 0;
  z-index: 90;
  display: flex;
  position: fixed;
  flex-direction: row;
  transform: translateX($content-width);
}

.sidebarWithAnimation {
  transition-property: transform;
  transition: $animation-translation-time ease-in-out;

  .tabLabel {
    transition: $animation-elements-time ease-in-out;
  }

  .tabIcon {
    transition: $animation-elements-time ease-in-out;

    &:after,
    &:before {
      transition: $animation-elements-time ease-in-out;
    }
  }
}

.sidebarOpen {
  transform: translateX(0px);

  .container {
    box-shadow: $background-shadow-content;
  }

  .header {
    display: flex;
    margin-bottom: 20px;
  }

  .tabLabel {
    border-color: $secondary;
  }

  .tabIcon {
    color: $secondary;
    border-color: $secondary;
  }
}

.sidebarLeft {
  left: 0;
}

.sidebarRight {
  right: 0;
}

.tabs {
  top: 0;
  bottom: 0;
  position: relative;
  align-self: center;
}

.container {
  padding: 30px 15px;
  width: $content-width;
  background: $right-sidebar-background;
  overflow-y: auto;
  display: flex;
}

.content {
  margin: auto;
  width: 100%;
}

.header {
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  flex-shrink: 0;
}

.cursorPointer {
  cursor: pointer;
}
