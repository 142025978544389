@import 'assets/styles/variables';
@import 'assets/styles/responsive';

$btn-size-small: 0.75rem;
$btn-size-regular: 0.875rem;
$btn-size-large: 1.2rem;

$btn-primary-color: #fff;
$btn-primary-bg: $primary;
$btn-primary-bg-hover: #cc3300;

$btn-primary-outline-color: $primary;
$btn-primary-outline-bg: transparent;
$btn-primary-outline-color-hover: #fff;
$btn-primary-outline-bg-hover: $primary;
$btn-primary-outline-border: 1px solid $primary;

$btn-secondary-color: #fff;
$btn-secondary-bg: $secondary;
$btn-secondary-bg-hover: #1c8c77;

$btn-secondary-outline-color: $secondary;
$btn-secondary-outline-bg: transparent;
$btn-secondary-outline-color-hover: #fff;
$btn-secondary-outline-bg-hover: $secondary;
$btn-secondary-outline-border: 1px solid $secondary;

$btn-success-color: #fff;
$btn-success-bg: $success;

$btn-failure-color: #fff;
$btn-failure-bg: $failure;
$btn-failure-bg-hover: #d62929;

.button {
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.small {
    font-size: $btn-size-small;
    min-height: 32px;
  }

  &.regular {
    font-size: $btn-size-regular;
    min-height: 40px;

    @include media-breakpoint-down(sm) {
      font-size: $btn-size-small;
      min-height: 32px;
    }
  }

  &.large {
    font-size: $btn-size-large;
    min-height: 48px;

    @include media-breakpoint-down(sm) {
      font-size: $btn-size-regular;
      min-height: 40px;
    }
  }

  text-transform: $btn-text-transform;

  &:hover {
    cursor: pointer;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed !important;
  }

  &.iconLeft .wrapper {
    i[class^='fi'] {
      margin-right: 8px;
    }
  }

  &.iconRight .wrapper {
    i[class^='fi'] {
      margin-left: 8px;
    }
  }
}

//ie10 min-height hack with flex
.wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
}

//primary
.primary {
  color: $btn-primary-color;
  background-color: $btn-primary-bg;

  &:hover:not(:disabled):not(.disabled) {
    background: $btn-primary-bg-hover;
  }
}

//primary-outline
.primary-outline {
  color: $btn-primary-outline-color;
  background-color: $btn-primary-outline-bg;
  border: $btn-primary-outline-border;

  &:hover:not(:disabled):not(.disabled) {
    color: $btn-primary-outline-color-hover;
    background: $btn-primary-outline-bg-hover;
  }

  &.loading::after {
    border-color: $btn-primary-outline-color;
  }
}

//secondary
.secondary {
  color: $btn-secondary-color;
  background-color: $btn-secondary-bg;

  &:hover:not(:disabled):not(.disabled) {
    background: $btn-secondary-bg-hover;
  }
}

//secondary-outline
.secondary-outline {
  color: $btn-secondary-outline-color;
  background-color: $btn-secondary-outline-bg;
  border: $btn-secondary-outline-border;

  &:hover:not(:disabled):not(.disabled) {
    color: $btn-secondary-outline-color-hover;
    background: $btn-secondary-outline-bg-hover;
  }

  &.loading::after {
    border-color: $btn-secondary-outline-color;
  }
}

.kebab-menu {
  height: 50px;
  width: 50px;

  &:focus {
    box-shadow: none;
  }
  &:hover {
    background: transparent;
  }
}

//success
.success {
  color: $btn-success-color;
  background-color: $btn-success-bg;
}

//failure
.failure {
  color: $btn-failure-color;
  background-color: $btn-failure-bg;

  &:hover:not(:disabled):not(.disabled) {
    background: $btn-failure-bg-hover;
  }
}

//link
.link {
  color: $btn-link-color;
  background: transparent;

  &:hover {
    color: $btn-link-color;
    text-decoration: none;
  }

  &.loading::after {
    border-color: $btn-link-color;
  }
}

//text overflow (wrap)
.textWrap {
  white-space: normal;
  height: auto;
}

//loading
.loading {
  position: relative;
  pointer-events: none;
  color: transparent !important;

  &::after {
    top: 50%;
    left: 50%;
    content: '';
    width: 1rem;
    height: 1rem;
    display: block;
    margin-top: -0.5rem;
    margin-left: -0.5rem;
    position: absolute;
    animation: spin-around 500ms infinite linear;
    border: 0.125rem solid $white;
    border-radius: 290486px;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
  }
}

.grouped {
  border-radius: 0;
  margin: 0 !important;

  &:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
  &:last-child {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
  &:not(:first-child) {
    // to remove double border between grouped buttons
    margin-left: -1px !important;
  }
}

@keyframes spin-around {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
