@import 'assets/styles/responsive';

.hideScroll {
  &::-webkit-scrollbar {
    display: none !important; /* Chrome */
  }

  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

.wrapper {
  overflow-x: scroll;

  :global(.ReactVirtualized__Grid.ReactVirtualized__Table__Grid) {
    overflow: initial !important;
  }
}

.striped {
  background: $table-accent-bg !important;
}

//region Basic Bootstrap .table class mapped to react-virtualized Table structure
:global {
  .virtualized-table {
    .ReactVirtualized__Table {
      width: 100%;
      max-width: 100%;
      margin-bottom: $spacer;
      background-color: $table-bg; // Reset for nesting within parents with `background-color`.

      .ReactVirtualized__Table__row {
        border-top: $table-border-width solid $table-border-color;
      }

      .ReactVirtualized__Table__headerColumn,
      .ReactVirtualized__Table__rowColumn {
        padding: $table-cell-padding;
        vertical-align: top;
      }

      .ReactVirtualized__Table__headerColumn {
        vertical-align: bottom;
        border-bottom: (2 * $table-border-width) solid $table-border-color;
      }
    }
  }
}
//endregion

//region Project <Table/> component styles mapped to react-virtualized structure
:global {
  .virtualized-table {
    .ReactVirtualized__Table {
      margin-bottom: 0;
    }

    .ReactVirtualized__Table .ReactVirtualized__Table__headerColumn {
      border: none;
      font-weight: 400;
      padding: 0 24px 8px;
      font-size: $table-head-font-size;
    }

    .ReactVirtualized__Table .ReactVirtualized__Table__row {
      background: $table-row-bg;
    }

    .ReactVirtualized__Table .ReactVirtualized__Table__row:first-of-type {
      border-top-left-radius: $table-border-radius;
      border-top-right-radius: $table-border-radius;
      border-top: none !important;
    }
    .ReactVirtualized__Table .ReactVirtualized__Table__row:last-of-type {
      border-bottom-left-radius: $table-border-radius;
      border-bottom-right-radius: $table-border-radius;
    }

    .ReactVirtualized__Table .ReactVirtualized__Table__row {
      overflow: visible !important;
    }

    .ReactVirtualized__Table .ReactVirtualized__Table__rowColumn {
      line-height: 32px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      margin: 0;
    }
    .ReactVirtualized__Table .ReactVirtualized__Table__headerColumn {
      margin: 0;
      align-self: flex-end;
      text-transform: initial;
    }

    @include media-breakpoint-down(md) {
      .ReactVirtualized__Table .ReactVirtualized__Table__rowColumn {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .ReactVirtualized__Table .ReactVirtualized__Table__rowColumn > * {
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
    }

    .ReactVirtualized__Table .ReactVirtualized__Table__rowColumn a:not(:global(.btn)) {
      color: $gray-900;
      text-decoration: underline;
    }

    .ReactVirtualized__Table .ReactVirtualized__Table__rowColumn a:not(:global(.btn)):hover {
      color: $link-color;
    }

    .ReactVirtualized__Table + .ReactVirtualized__Table {
      margin-top: 24px;
    }

    :global {
      .ReactVirtualized__Table .filled-row {
        background-color: rgba($gray-600, 0.3);
        color: rgba($gray-600, 0.6);

        .ReactVirtualized__Table__rowColumn {
          a,
          strong {
            color: rgba($gray-600, 0.6);
          }
        }
      }
    }
  }
}

// Fix for calendar being bugged at the bottom of the virtualized-box
:global {
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }

  .ReactVirtualized__List {
    overflow: visible !important;
  }
}

:global {
  .compress-padding {
    .ReactVirtualized__Table {
      .ReactVirtualized__Table__headerColumn,
      .ReactVirtualized__Table__rowColumn {
        padding: 8px !important;
      }

      .ReactVirtualized__Table__rowColumn:first-of-type,
      .ReactVirtualized__Table__headerColumn:first-of-type {
        padding-left: 24px !important;
      }
      .ReactVirtualized__Table__rowColumn:last-of-type,
      .ReactVirtualized__Table__headerColumn:last-of-type {
        padding-right: 24px !important;
      }
    }
  }
}
//endregion
