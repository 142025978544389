@import 'assets/styles/responsive';

.button {
  width: 100%;
}

.thead th :not(:last-child),
.thead i {
  cursor: pointer;
}
