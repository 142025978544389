@import 'assets/styles/responsive';

.row {
  border-top: $table-border-width solid $table-border-color;
}

.row:first-child {
  border-top: none;
}

.row td:first-of-type,
th:first-of-type {
  padding-left: 24px;
}

.row td:last-of-type,
th:last-of-type {
  padding-right: 24px;
}

.row td {
  vertical-align: middle;
}
