@import 'assets/styles/responsive';

.title {
  align-items: center;
  display: flex;
  min-height: 32px;
  margin: 0 0 12px 16px;
}

.container {
  display: flex;
  align-items: flex-start;
  min-height: 67px;
  flex-wrap: wrap;
  padding: 16px 0;
  position: relative;

  @include media-breakpoint-up(xl) {
    padding: 16px 72px 16px 0;
  }

  [class^='col-'] {
    padding: 0 8px;

    > i {
      color: $secondary;
      cursor: pointer;
    }

    :global(.form-group) {
      flex: 1;
    }
  }

  &:first-of-type {
    border-radius: 8px 8px 0 0;
  }

  &:nth-of-type(2n) {
    background: rgba($input-border-color, 0.2);
  }

  &:nth-of-type(2n + 1) {
    background: rgba($input-border-color, 0.1);
  }

  &:last-of-type {
    border-radius: 0 0 8px 8px;
  }
}

.name {
  min-width: 195px;
}

.icons {
  position: absolute;
  right: 12px;
  top: 44px;

  @include media-breakpoint-down(lg) {
    bottom: 2px;
    left: 8px;
    right: auto;
    top: auto;
  }
}

.icon {
  cursor: pointer;
  font-size: 20px;
}

.remove {
  @extend .icon;

  color: $red;
  margin-left: 8px;
}

.clone {
  @extend .icon;

  color: $secondary;
}
