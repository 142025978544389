@import 'assets/styles/variables';

.rc-tooltip {
  z-index: $z-index-tooltip;

  .rc-tooltip-inner {
    min-height: 0;
    padding: 4px 8px;
    max-width: 200px;
    box-shadow: none;
    line-height: 14px;
    border-radius: 2px;
    background: #242424;
  }
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  border-top-color: #242424;
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  border-right-color: #242424;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  border-left-color: #242424;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  border-bottom-color: #242424;
}

.tooltip-enter {
  opacity: 0.01;
}

.tooltip-enter.tooltip-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
}

.tooltip-leave {
  opacity: 1;
}

.tooltip-leave.tooltip-leave-active {
  opacity: 0.01;
  transition: opacity 150ms ease-in;
}

.tooltip-appear {
  opacity: 0.01;
}

.tooltip-appear.tooltip-appear-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
}
