$vaanaHeaderHeight: 90px;
$vaanaWrapperWidth: 1440px;

.wrapper {
  width: $vaanaWrapperWidth;
  max-width: 100vw;
  margin: 0 auto;
}

.navWrapper {
  @extend .wrapper;
  position: fixed;
  z-index: 10;
  right: 0;
  left: 0;
}

.header {
  border-radius: 0;
  padding: 0 2.5%;
  height: $vaanaHeaderHeight;
  display: flex;
  align-items: center;
}

.logo {
  height: auto;
  width: 200px;
  vertical-align: middle;
  display: inline-block;
}

.link {
  height: $vaanaHeaderHeight;
  display: inline-flex;
  align-items: center;
}

.body {
  margin-top: $vaanaHeaderHeight;
}
