@import 'assets/styles/responsive';
@import 'assets/styles/variables';

$sidebar-border: 1px solid $gray-light;
$sidebar-width-collapsed: 72px;
.sidebar {
  z-index: $z-index-zero;
  color: #fff;
  flex: 0 0 $sidebar-width-collapsed;
  min-height: 100vh;
  position: relative;
  background: $sidebar-background;

  @include media-breakpoint-up(sm) {
    flex: 0 0 $sidebar-width;
    max-width: $sidebar-width;
  }
}

.collapsed {
  flex: 0 0 $sidebar-width-collapsed;
}

.container {
  top: 0;
  width: 100%;
  z-index: 80;
  padding-bottom: 48px;
}

.tooltipsContainer {
  width: 300px;
  position: absolute;
}

.collapse {
  right: 0;
  top: 16px;
  color: #fff;
  width: 36px;
  height: 24px;
  outline: none !important;
  display: none;
  cursor: pointer;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: 4px 0 0 4px;
  background: rgba($gray-900, 0.7);

  @include media-breakpoint-up(sm) {
    display: flex;
  }
}

.collapse:hover,
.collapse:focus {
  color: #fff;
  text-decoration: none;
  background: rgba($gray-900, 0.9);
}

.header {
  background: $white;
  padding: 0 1rem;
  border-bottom: 1px solid $gray-300;
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;
  height: 4rem;
  z-index: $z-index-controls;

  :global(.row) {
    min-height: 4rem;
  }

  :global(svg) {
    width: 100%;
    max-width: 100px;
    min-height: 4rem;
  }
}

.switch {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  border: 0;
  border-radius: 50%;
  padding: 0.5rem;
  color: $sidebar-link-active-color;
  background: $gray-100;
  outline: none !important;
}

.menu {
  display: none;
  position: fixed;
  top: 4rem;
  left: 0;
  width: 100vw;
  height: calc(100vh - 4rem);
  background: $white;
  z-index: $z-index-controls;
  overflow: hidden;
  overflow-y: scroll;
}

.menuOpen {
  display: block;
}
