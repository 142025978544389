@import 'assets/styles/responsive';

.table {
  margin-bottom: 0;

  :global(.filled-row) {
    color: rgba($gray-600, 0.8);

    & td:not(:last-of-type),
    & td:not(:last-of-type) * {
      color: rgba($gray-600, 0.8);
    }
  }
}

.table tbody tr:last-child td:first-child {
  border-bottom-left-radius: $table-border-radius;
}

.table tbody tr:last-child td:last-child {
  border-bottom-right-radius: $table-border-radius;
}

.table td a:not(:global(.btn)) {
  color: $gray-900;
  text-decoration: underline;
}

.table + .table {
  margin-top: 24px;
}

.scrollable {
  overflow-x: auto;
}
