@import 'assets/styles/responsive';
@import 'assets/styles/variables';

.wrapper {
  padding: $card-padding / 2 $card-padding;
  background: $table-accent-bg;
  z-index: 200;
  width: 100%;
}

.title {
  color: $secondary;
}

.title i {
  font-size: 1rem;
  padding-right: 10px;
}

.submit {
  height: 40px;
  width: 100%;
  min-width: 90px;

  @include media-breakpoint-up(sm) {
    margin-top: 24px;
  }
}

.columns {
  display: flex;
  flex-wrap: wrap;
  columns: 1;
}

.column {
  flex: 1;
  margin-right: 8px;

  &:last-of-type {
    margin-right: 0;

    @include media-breakpoint-up(xl) {
      flex: 0 0 auto;
    }
  }

  @include media-breakpoint-down(lg) {
    flex: 0 0 calc(50% - 4px);
    width: calc(50% - 4px);

    &:nth-of-type(2n + 2) {
      margin-right: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    flex: 1 0 100%;
  }
}
