@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Rubik/rubik-v14-latin-ext-regular.eot');
  src: local(''), url('../fonts/Rubik/rubik-v14-latin-ext-regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rubik/rubik-v14-latin-ext-regular.woff2') format('woff2'),
    url('../fonts/Rubik/rubik-v14-latin-ext-regular.woff') format('woff'),
    url('../fonts/Rubik/rubik-v14-latin-ext-regular.ttf') format('truetype'),
    url('../fonts/Rubik/rubik-v14-latin-ext-regular.svg#Rubik') format('svg');
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Rubik/rubik-v14-latin-ext-500.eot');
  src: local(''), url('../fonts/Rubik/rubik-v14-latin-ext-500.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rubik/rubik-v14-latin-ext-500.woff2') format('woff2'),
    url('../fonts/Rubik/rubik-v14-latin-ext-500.woff') format('woff'),
    url('../fonts/Rubik/rubik-v14-latin-ext-500.ttf') format('truetype'),
    url('../fonts/Rubik/rubik-v14-latin-ext-500.svg#Rubik') format('svg');
}
