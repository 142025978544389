@import 'assets/styles/responsive';

.field {
  margin: 0 0 16px;

  strong {
    display: block;
    font-size: 12px;
    color: $summary-field-color;
    margin-bottom: 4px;
  }
}

.field.equalFormHeights {
  margin: 0 0 24px;
  height: 66px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 12px;
  }

  strong {
    padding-top: 4px;
    margin-bottom: 12px;
    font-weight: 400;
  }
}
