@import 'assets/styles/variables';

.group {
  position: relative;
}

.label {
  font-size: 12px;
  min-height: 18px;
  text-indent: 16px;
  margin-bottom: 4px;
}

.required::after {
  color: $red;
  content: ' *';
  line-height: initial;
}
