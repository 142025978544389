@import 'assets/styles/variables';

.container {
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border: 2px $secondary solid;
  border-radius: $border-radius-lg;
}

.button {
  padding: 0;
  border: none;
  font-size: 14px;
  cursor: pointer;
  background: none;
  color: $secondary;
  margin: 8px 16px 0 8px;
  display: inline-block;
  text-decoration: underline;
  outline: none !important;
}

.icon {
  margin: 0 8px 0 0;
}
