@import 'assets/styles/variables';

.startInput,
.endInput {
  border: 0;
  background: transparent;

  &:focus {
    border: 0;
    box-shadow: none;
    background: transparent;
  }
}

.inputSeparator {
  align-items: center;
  color: $secondary;
  display: flex;
  justify-content: center;
  flex: 0.2;
}
