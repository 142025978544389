@import 'assets/styles/variables';

.container {
  height: 330px;
  line-height: 1.1;
  text-align: center;
  background: rgba($gray-100, 0.1);

  &.withBackground {
    background-color: $table-row-bg;
    border-radius: $table-border-radius;
  }
}

.container h4 {
  margin-bottom: 12px;
}

.container p {
  font-size: 12px;
}

.icon {
  position: relative;
  padding: 70px 0 28px;
}

.icon i {
  font-size: 40px;
}

.icon :global(.fi-computer) {
  color: $input-border-color;
}

.icon :global(.fi-search) {
  color: $secondary;
  position: absolute;
  margin: -3px 0 0 -24px;
}
