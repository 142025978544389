@import 'assets/styles/variables';

.typography {
  margin: 0;
  max-width: 100%;
}

.disabled {
  opacity: 0.5;
}

//colors
.primary {
  color: $primary;
}

.secondary {
  color: $secondary;
}

.grey {
  color: $gray;
}

.red {
  color: $red;
}

.failure {
  color: $failure;
}

.success {
  color: $success;
}

.greyDark {
  color: $gray-dark;
}

//variants
.header {
  font-size: 1.6875rem; //27px
  font-weight: 500;
}

.largeText {
  font-size: 1.25rem; //20px
}

.largeTextBold {
  @extend .largeText;
  font-weight: 500;
}

.subtitle {
  font-size: 1rem; //16px
  font-weight: 500;
}

.bodyText {
  font-size: 0.875rem; //14px
}

.bodyTextBold {
  @extend .bodyText;
  font-weight: 500;
}

.smallText {
  font-size: 0.75rem; //12px
}

.smallTextBold {
  @extend .smallText;
  font-weight: 500;
}
