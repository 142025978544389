.modalCard {
  text-align: center;
  width: 520px;
  padding: 60px 0px;
}

.modalBody {
  line-height: 40px;
}

.button {
  width: 100px;
}
