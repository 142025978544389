$labelSpacing: 10px;

.selectionBase {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.selectionBaseMultiple {
  display: flex;
}

.componentWrapper {
  position: relative;
}

.componentWrapperMultiple {
  width: 100%;
}

.label-top {
  flex-direction: column;
  align-items: flex-start;
}

.label-bottom,
.label-bottom-center {
  flex-direction: column;
  align-items: flex-start;

  .componentWrapper {
    margin-top: $labelSpacing;
  }
}

.label-bottom-center {
  align-items: center;
}

.label-right {
  flex-direction: row-reverse;

  .componentWrapper {
    margin-right: $labelSpacing;
  }
}

.label-left {
  .componentWrapper {
    margin-right: 0;
    margin-left: $labelSpacing;
  }
}

.isDisabled {
  input,
  label {
    cursor: not-allowed;
  }
}

.column-align-center {
  text-align: center;
}

.column-align-left {
  text-align: left;
}

.label {
  cursor: pointer;
}

.control {
  cursor: pointer;
}
