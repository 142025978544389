.header {
  cursor: pointer;
}

.wrapper {
  position: relative;
  padding-right: 24px;
}

.icon {
  position: absolute;
  right: 0;
}
