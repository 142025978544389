$gutter: 24px;

.row,
.row :global(.row) {
  margin-left: $gutter / -2;
  margin-right: $gutter / -2;

  [class^='col-'] {
    padding-left: $gutter / 2;
    padding-right: $gutter / 2;
  }
}
