@import 'assets/styles/variables';

$height: 16px;
$width: 32px;

.toggle {
  display: flex;
  align-items: center;
  width: $width;
}

.input {
  position: absolute;
  height: $height;
  width: $width;
  z-index: 10;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.slider {
  margin: 0;
  height: $height;
  flex: 0 0 $width;
  cursor: pointer;
  position: relative;
}

.slider::after,
.slider::before {
  content: '';
  display: block;
  transition: background 200ms, transform 200ms;
}

.slider::before {
  background: rgba(#8b8598, 0.2);
  border-radius: 10px;
  border: 0.5px solid rgba($gray, 0.8);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.slider::after {
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 50%;
  background: #8b8598;
}

input:checked + .slider::before {
  border-color: rgba($green, 0.8);
}

input:checked + .slider::after {
  background: $secondary;
  transform: translateX(16px);
}

//invalid
:global(.invalid) .toggle {
  .slider::after,
  .slider::before {
    border-color: $input-invalid-border-color;
  }

  .slider::after {
    background: $input-invalid-border-color;
  }
}

//disabled
:global(.disabled) .toggle {
  .slider::after,
  .slider::before {
    border-color: $input-disabled-color;
  }

  .slider::after {
    background: $input-disabled-color;
  }
}
