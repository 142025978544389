@import 'assets/styles/responsive';
@import 'assets/styles/variables';

.array {
  z-index: $z-index-forms;

  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

.wrapper {
  background: rgba($input-border-color, 0.2);
  padding: 16px 10px 0;
  margin-bottom: 8px;
  border-radius: $border-radius;
  overflow: hidden;
}

.DateRangeBackdrop {
  top: 0;
}
