@import 'assets/styles/responsive';

.cell {
  line-height: 1.5;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  border-top: none !important;
}

.cell > * {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.cell a:not(:global(.btn)) {
  color: $gray-900;
  text-decoration: underline;
}

.cell a:not(:global(.btn)):hover {
  color: $link-color;
}

.header {
  border: none !important;
  font-weight: 400;
}
