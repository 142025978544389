@import 'assets/styles/responsive';

.wrapper {
  font-size: 12px;
  margin-bottom: 7px;
  padding: 0 4px 0;
  position: relative;

  @include media-breakpoint-down(lg) {
    padding-bottom: 24px;
  }
}

.wrapper:nth-child(odd) {
  background-color: $table-accent-bg;
}

.wrapper:nth-child(even) {
  background-color: $table-row-bg;
}

.row {
  margin-left: 3px;
  margin-right: 3px;
}

.row [class^='col-'] {
  padding-left: 4px;
  padding-right: 4px;

  & strong {
    padding-top: 0;
  }
}

.remove {
  cursor: pointer;
  font-size: 20px;
  color: $red;
  margin-left: 8px;
  float: right;
  padding-top: 20px;
}
