@import 'assets/styles/variables';

:global(.invalid-feedback) {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: $input-margin;
}

.errorMessage {
  line-height: 0.75rem;
}
