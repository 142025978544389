@import 'assets/styles/responsive';

.actions {
  display: flex;
}

.align-top {
  vertical-align: top !important;
}

.align-center {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.row {
  border-bottom: $table-border-width solid $table-border-color;
}
