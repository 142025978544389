@import 'assets/styles/variables';

.business {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  font-size: 16px;
  background: none;
  line-height: 18px;
  align-items: center;
  text-align: left;
  justify-content: space-between;
  border: 0;
}

button.business:hover,
button.business:focus {
  cursor: pointer;
  text-decoration: none;
  background: rgba($secondary, 0.1);
}

button.business.active {
  &:hover,
  &:focus {
    color: $secondary;
    background: rgba($secondary, 0.8);
  }
  .select {
    color: $white;
  }
}

.select:hover,
.select:focus {
  color: $secondary;
}

.nested {
  margin-left: 48px;
  width: calc(100% - 48px);
}

.name {
  color: $gray-900;
  display: inline-flex;
  align-items: center;
  svg {
    padding-right: 4px;
  }
}

.hourglassIcon {
  fill: $red;
  height: 18px;
  align-self: start;
}

.active {
  color: $white;
  background: $secondary;
  margin: 0;
}

.active .name {
  color: $white;
}
