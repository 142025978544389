@import '~react-datepicker/dist/react-datepicker-cssmodules.css';
@import 'assets/styles/variables';

$datepicker__font-size: 16px;
$datepicker__input-radius: 4px;
$datepicker__border-radius: 4px;
$datepicker__font-family: inherit;
$datepicker__text-color: inherit;

$datepicker__day-color: #878787;
$datepicker__day-background: #f2f2f2;

$datepicker__day-outside-month-color: rgba($datepicker__day-color, 0.5);
$datepicker__day-outside-month-background: rgba($datepicker__day-background, 0.5);

$datepicker__day-size: 40px;

:global {
  div.react-datepicker-wrapper,
  div.react-datepicker__input-container {
    display: flex;
    flex: 1;
    height: 100%;
  }
}

.calendar {
  & :global {
    .react-datepicker-popper {
      z-index: 300;
      margin-bottom: 24px;
    }

    .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
      border-top-color: #fff;

      &:before {
        border-top-color: rgba($gray-900, 0.24);
      }
    }

    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
      border-bottom-color: #fff;

      &:before {
        border-bottom-color: rgba($gray-900, 0.24);
      }
    }

    .react-datepicker {
      padding: 16px;
      border-color: rgba($gray-900, 0.24);
      box-shadow: 0 8px 16px 0 rgba(20, 25, 26, 0.1);
    }

    .react-datepicker__header {
      border: none;
      padding-top: 0;
      background: none;
    }

    .react-datepicker__navigation {
      top: 24px;
    }

    .react-datepicker__month {
      margin: 0;
    }

    .react-datepicker__current-month {
      font-size: 16px;
      color: $gray-900;
      font-weight: 600;
      line-height: 30px;
    }

    .react-datepicker__day-names {
      margin-top: 8px;

      .react-datepicker__day-name {
        margin: 0;
        color: #9d9e9e;
        font-size: 10px;
        width: $datepicker__day-size;
        line-height: $datepicker__day-size;
      }
    }

    .react-datepicker__day {
      margin: 0;
      z-index: $z-index-normal;
      border-radius: 0;
      position: relative;
      width: $datepicker__day-size;
      color: $datepicker__day-color;
      line-height: $datepicker__day-size;
      background: $datepicker__day-background;

      &:hover {
        color: #fff;
        border-radius: 0;
        background: rgba($secondary, 0.5);
      }

      &:after {
        left: 0;
        right: 0;
        top: 8px;
        bottom: 8px;
        z-index: $z-index-background;
        content: '';
        padding: 5px;
        display: block;
        position: absolute;
      }

      &--outside-month {
        color: $datepicker__day-outside-month-color;
        background: $datepicker__day-outside-month-background;
      }

      &--disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }

      &--outside-month {
        color: $datepicker__day-outside-month-color;
        background: $datepicker__day-outside-month-background;
      }

      &--selected:not(.react-datepicker__day--in-range) {
        color: #fff;

        &:after {
          border-radius: 25px;
          background: $secondary;
        }
      }

      &--in-range {
        color: #fff;

        &:after {
          background: $secondary;
        }
      }

      &--in-selecting-range {
        background-color: rgba($secondary, 0.2);
      }

      &--range-start {
        &:after {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        }
      }

      &--range-end {
        &:after {
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
        }
      }

      &--keyboard-selected:not(.react-datepicker__day--today) {
        color: #fff;
        background: $secondary;
      }

      &--today {
        background: rgba($primary, 0.3);
        color: #fff;
        font-weight: 600;
      }

      &--highlighted {
        background: rgba($primary, 0.15);
      }
    }

    .react-datepicker__day--highlighted {
      background: rgba($primary, 0.15);
    }
  }
}

.reverse-popper {
  :global {
    .react-datepicker {
      position: absolute;
      right: 0;
      top: 0;
    }

    .react-datepicker__triangle {
      left: 130px;
    }
  }
}
