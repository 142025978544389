.actionButtonGroup {
  display: flex;
}

.actionButton {
  &:not(:first-child) {
    margin-left: 5px;
  }

  &:not(:last-child) {
    margin-right: 5px;
  }
}
