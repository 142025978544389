.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
}

.value {
  font-size: 1rem;
}
