@import 'assets/styles/variables';

$tab-radius: 8px;
$triangle-size: 8px;
$disabled-tab: #6f6c68;
$background-shadow-label: 2px 0px 12px -3px rgba(0, 0, 0, 0.4);
$background-shadow-icon: -4px 1px 18px -5px rgba(0, 0, 0, 0.4);

$animation-elements-time: 0;

.tab {
  display: flex;
  margin: 20px 0 0;
  position: relative;
  align-items: flex-end;
  flex-direction: column;

  &:after {
    top: 0;
    width: 0;
    height: 0;
    bottom: 0;
    z-index: $z-index-forms;
    content: '';
    margin: auto;
    display: block;
    position: absolute;
    border-style: solid;
    right: -1 * $triangle-size;
    border-width: $triangle-size 0 $triangle-size $triangle-size;
    border-color: transparent transparent transparent transparent;
    transition: $animation-elements-time ease-in-out;
  }
}

.tabDisabled {
  pointer-events: none;

  .tabLabel {
    color: $disabled-tab;
    border-color: transparent;
  }

  .tabIcon {
    border-color: transparent;

    i {
      color: $disabled-tab;
    }
  }
}

.tabActive {
  &:after {
    border-color: transparent transparent transparent $secondary;
  }

  .tabLabel {
    border: 0;
    color: #fff;
    background-color: $secondary;

    &:after {
      background-color: transparent;
    }
  }

  .tabIcon {
    border: 0;
    background-color: $secondary;

    &:before {
      background-color: $secondary;
    }

    i {
      color: #fff;
    }
  }
}

.tabLabel {
  background: #fff;
  color: $secondary;
  padding: 5px 0 10px 0;
  text-orientation: mixed;
  writing-mode: vertical-rl;
  transform: rotateZ(180deg);
  border: 1px solid transparent;
  border-radius: 0 0 $tab-radius 0;
  display: flex;
  user-select: none;

  &:after {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
    display: block;
    position: absolute;
    border-radius: 0 0 $tab-radius 0;
    box-shadow: $background-shadow-label;
  }
}

.tabIcon {
  top: -1px;
  display: flex;
  font-size: 20px;
  position: relative;
  align-items: center;
  padding: 0 8px 0 3px;
  background: #fff;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: $tab-radius 0 0 $tab-radius;

  &:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index-background;
    content: '';
    display: block;
    position: absolute;
    box-shadow: $background-shadow-icon;
    border-radius: $tab-radius 0 0 $tab-radius;
  }

  &:before {
    top: -2px;
    right: 0;
    content: '';
    height: 4px;
    display: block;
    position: absolute;
    background-color: #fff;
    width: calc(100% - 7px);
  }

  i {
    color: $secondary;
  }
}

.cursorPointer {
  cursor: pointer;
}
