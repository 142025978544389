@import 'assets/styles/responsive';

$gutter: 12px;

.tiles {
  display: flex;
  flex-flow: row wrap;
  margin-left: $gutter / -2;
  margin-right: $gutter / -2;

  @include media-breakpoint-up(lg) {
    .tile:nth-child(2) {
      margin-top: 0;

      :global(.sidebar-open) & {
        margin-top: $gutter;
      }
    }
  }
}

.tile {
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 $gutter / 2;
  flex-direction: column;

  + .tile {
    margin-top: $gutter;
  }

  @include media-breakpoint-up(lg) {
    flex: 0 0 50%;
    max-width: 50%;

    :global(.sidebar-open) & {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.collapsed {
  display: flex;
  border-radius: 8px;
  align-items: center;
  padding: 1.125rem 1.5rem;
  background: rgba(#e8def0, 0.2);
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.displayed {
  font-size: 12px;
  color: $secondary;
  margin-right: 10px;
}
