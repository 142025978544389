@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import 'assets/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
  padding: 24px;
  border-radius: 8px;

  @include media-breakpoint-down(md) {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.success {
  background: rgba($green, 0.1);
}

.error {
  background: rgba($red, 0.1);
}

.processing {
  background: rgba($gray-300, 0.1);
}

.titleContainer {
  align-items: center;
  display: flex;

  .centred & {
    flex-direction: column;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.image {
  display: block;
  line-height: 70px;
  margin-right: 24px;

  .centred & {
    margin: 0;
  }

  @include media-breakpoint-down(md) {
    margin: 0;
  }
}

.check {
  display: block;
  font-size: 50px;

  .success & {
    color: $green;
  }

  .error & {
    color: $red;
  }
}

.loading {
  width: 50px;
  height: 50px;
  margin: 10px 0;
  border-radius: 50%;
  position: relative;
  background: $gray-300;

  &::after {
    top: 50%;
    left: 50%;
    content: '';
    width: 1rem;
    height: 1rem;
    display: block;
    margin-top: -0.5rem;
    margin-left: -0.5rem;
    position: absolute;
    animation: spin-around 500ms infinite linear;
    border: 0.125rem solid $white;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
  }
}

.title {
  font-size: 24px;
  margin-bottom: 8px;

  .centred & {
    margin: 8px 0 9px;
  }

  @include media-breakpoint-down(md) {
    margin: 8px 0 9px;
  }
}

.description {
  margin: -24px 0 0 75px;

  .centred & {
    margin: 0;
  }

  @include media-breakpoint-down(md) {
    margin: 0;
  }
}

.centred {
  margin: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 40px 24px 60px;
}

@keyframes spin-around {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
