@import 'assets/styles/responsive';

@mixin hover() {
  border-radius: 4px;
  text-decoration: none;
  color: $sidebar-link-hover;
  background: rgba($gray-600, 0.1);
}

.balance {
  font-size: 16px;
  padding: 11px 0;
  color: $secondary;
  margin: 0;
}

:global(.sidebar-open) .balance {
  margin: 4px 24px 0;
}

.balance i {
  margin: 1px 12px 0;
  color: $sidebar-icon-color;
}

.collapsed:hover {
  @include hover();
}
