@import 'assets/styles/variables';

.button {
  font-weight: 500;
  text-transform: $btn-text-transform;

  &:hover {
    cursor: pointer;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
  }

  span + i,
  i + span {
    margin-left: 8px;
  }
}

//
// Link button
//

.link {
  font-weight: 500;
  color: $btn-link-color;
}

.link:hover {
  color: $btn-link-color;
  text-decoration: none;
  border: 1px $btn-link-color solid;
}

.link.loading {
  border: 1px $btn-link-color solid;
}

.link.loading::after {
  border-color: $btn-link-color;
  border-right-color: transparent;
  border-top-color: transparent;
}

.link-small {
  padding: 0;
  border: none;
  font-size: 14px;
  color: $btn-link-color;
  display: inline-block;
  text-decoration: underline;
  outline: none !important;
  font-weight: 400;
}

.link-small:hover {
  text-decoration: underline;
}

.tiny {
  display: flex;
  font-size: 14px;
  line-height: 14px;
  padding: 7px 10px;
  align-items: center;
}

.tiny i {
  font-size: 16px;
  line-height: 18px;
  margin-left: 3px;
}

.small {
  font-size: 12px;
  padding: 6px 12px;
  line-height: 18px;
  align-items: center;
  display: inline-flex;
  text-transform: none;
  justify-content: center;
}

.small i {
  font-size: 16px;
  line-height: 18px;
  display: inline-block;
  vertical-align: middle;
}

.medium {
  font-size: 16px;
  line-height: 18px;
  padding: $btn-md-padding;
}

.large {
  font-size: 16px;
  line-height: 24px;
  padding: $btn-lg-padding;
  border-radius: $btn-border-radius-lg;
}

.loading {
  position: relative;
  pointer-events: none;
  color: transparent !important;

  &::after {
    top: 50%;
    left: 50%;
    content: '';
    width: 1rem;
    height: 1rem;
    display: block;
    margin-top: -0.5rem;
    margin-left: -0.5rem;
    position: absolute;
    animation: spin-around 500ms infinite linear;
    border: 0.125rem solid $white;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
  }
}

@if $reverse-secondary-btn {
  .danger {
    border-color: $red;
    background-color: $red;
  }

  .danger:hover,
  .danger:active:not(:disabled):not(.disabled) {
    border-color: darken($red, 15%);
    background-color: darken($red, 15%);
  }

  .danger.disabled,
  .danger:disabled {
    border-color: $red;
    background-color: $red;
  }

  .danger.focus,
  .danger:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($red, 0.5);
  }

  .secondary {
    border-color: $primary;
    background-color: $primary;
  }

  .secondary:hover,
  .secondary:active:not(:disabled):not(.disabled) {
    border-color: darken($primary, $theme-color-interval);
    background-color: darken($primary, $theme-color-interval);
  }

  .secondary.disabled,
  .secondary:disabled {
    border-color: $primary;
    background-color: $primary;
  }

  .secondary.focus,
  .secondary:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($primary, 0.5);
  }

  .outline-secondary {
    color: $primary;
    border-color: $primary;
  }

  .outline-secondary:hover,
  .outline-secondary:active:not(:disabled):not(.disabled) {
    border-color: $primary;
    background-color: $primary;
  }

  .outline-secondary.focus,
  .outline-secondary:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($primary, 0.5);
  }

  .outline-secondary.disabled,
  .outline-secondary:disabled {
    color: $primary;
  }

  .primary {
    border-color: $secondary;
    background-color: $secondary;
  }

  .primary:hover,
  .primary:active:not(:disabled):not(.disabled) {
    border-color: darken($secondary, $theme-color-interval);
    background-color: darken($secondary, $theme-color-interval);
  }

  .primary.disabled,
  .primary:disabled {
    border-color: $secondary;
    background-color: $secondary;
  }

  .primary.focus,
  .primary:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($secondary, 0.5);
  }

  .outline-primary {
    color: $secondary;
    border-color: $secondary;
  }

  .outline-primary:hover,
  .outline-primary:active:not(:disabled):not(.disabled) {
    border-color: $secondary;
    background-color: $secondary;
  }

  .outline-primary.focus,
  .outline-primary:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($secondary, 0.5);
  }

  .outline-primary.disabled,
  .outline-primary:disabled {
    color: $secondary;
  }
}

:global(.btn-outline-secondary).loading::after {
  border-color: $secondary;
  border-right-color: transparent;
  border-top-color: transparent;
}

:global(.btn-outline-danger).loading::after {
  border-color: $red;
  border-right-color: transparent;
  border-top-color: transparent;
}

:global(.btn-outline-success):hover {
  color: #fff;
}

:global(.btn-transparent),
:global(.btn-transparent):hover {
  background-color: transparent;
}

@keyframes spin-around {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.triangle {
  font-size: 0.5em;
  margin-left: 7px;
  vertical-align: middle;
}

.triangle--up {
  &::after {
    content: '\25B2';
  }
}

.triangle--down {
  &::after {
    content: '\25BC';
  }
}

.white {
  display: flex;
  padding: 18px 32px;
  align-items: center;
  color: $white-button-color;
  background: $white-button-background;
}

.white i {
  height: 24px;
  display: block;
  font-size: 24px;
  line-height: 24px;
}

.white:focus {
  box-shadow: none;
}
