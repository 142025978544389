@import 'assets/styles/variables';

.container {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  height: $control-regular-size;
}

.label {
  margin-right: 10px;
}

.container a {
  color: inherit;
  text-decoration: inherit;
}
