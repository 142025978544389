@import 'assets/styles/variables';

.inputWrapper {
  display: flex;
  align-items: center;
  position: relative;

  & {
    background: #fff;
    border-radius: $input-border-radius;
    border: $input-border-size solid $input-border-color;
  }

  &:focus-within {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
  }

  &:global(.size) {
    &-small {
      height: $control-small-size;
    }

    &-regular {
      height: $control-regular-size;
    }

    &-auto {
      height: $control-auto-size;
    }
  }
}

.inputBase {
  //styles for is-focused
  &.isFocused {
    & > .inputWrapper {
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
      z-index: $z-index-controls;
    }
  }

  //styles for is-disabled
  &.isDisabled:not(:global(.disabled-light)) {
    & > .inputWrapper {
      border-color: $input-disabled-bg;
      background: $input-disabled-bg;
    }
  }

  &.isDisabled {
    :global(.form-control) {
      color: $input-disabled-color;

      &:not(:global(textarea)) {
        padding-left: 0;
      }
    }

    :global(i[class^='fi']) {
      color: $input-disabled-color;
    }

    & > .inputWrapper > input {
      cursor: not-allowed;
    }
  }

  //styles for is-invalid
  &.isInvalid {
    & > .inputWrapper {
      border-color: $input-invalid-border-color;
      box-shadow: $input-invalid-box-shadow;
    }
  }

  //styles for with-icon
  &.withIcon {
    :global(.form-control) {
      padding-left: 0;
    }
  }

  //global overwrite bootstrap styles
  :global {
    .form-control,
    .input-group-prepend,
    .input-group-append {
      height: 100%;
    }

    .form-control {
      border: 0;
      box-shadow: none;
      padding: $input-padding;
      background: transparent;

      &:disabled,
      &[readonly] {
        background: transparent;
      }
    }

    .input-group-prepend,
    .input-group-append {
      background: transparent;
      border: 0;
      z-index: 10;

      .input-group-text {
        border: 0;
        background: transparent;
      }
    }

    //for input with disabled icon
    .input-group-prepend:nth-of-type(2) {
      .input-group-text {
        padding-left: 0;
      }
    }
  }
}
