@import 'assets/styles/variables';

.customHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.navigationButton {
  padding: 0;
  align-items: center;
  background: none;
  border-radius: 4px;
  border: 1px rgba($secondary, 0.2) solid;
  color: $secondary;
  display: flex;
  font-size: 12px;
  height: $control-regular-size;
  justify-content: center;
  width: $control-regular-size;

  &:hover {
    border-color: rgba($secondary, 0.7);
  }

  &:focus {
    box-shadow: none;
  }

  &:first-of-type {
    margin-right: 5px;
  }

  &:last-of-type {
    margin-left: 5px;
  }
}

.monthSelect {
  min-width: 120px;
  margin-right: 2px;
}

.yearSelect {
  min-width: 85px;
  margin-left: 2px;
}
