@import 'assets/styles/variables';

.container {
  font-size: 12px;
  position: relative;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.row {
  margin: 0;
  position: relative;
  align-items: center;
  padding-right: 60px;
}

.selectAll {
  display: flex;
  justify-content: flex-end;
  margin-right: 13px;
}

.reference {
  padding-left: 6px;
}
