@import 'assets/styles/variables';

$gutter: 24px;

.row,
.row :global(.row) {
  margin-left: $gutter / -2;
  margin-right: $gutter / -2;

  [class^='col-'] {
    padding-left: $gutter / 2;
    padding-right: $gutter / 2;
  }
}

.icon {
  color: $secondary;
  font-size: 1.1rem;
}

.button {
  background: none;
  border: none;
  color: $primary;
  cursor: pointer;
  font-size: 11px;
  font-weight: $font-weight-bold;
  margin-left: auto;
}
