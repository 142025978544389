@import 'assets/styles/responsive';
@import 'assets/styles/variables';

.footer {
  z-index: $z-index-normal;
  color: #fff;
  position: relative;
  margin-top: -368px;
  background: $primary;

  @include media-breakpoint-up(md) {
    padding-top: 150px;
    padding-bottom: 50px;
  }

  @include media-breakpoint-down(sm) {
    position: initial;
    margin-top: initial;
  }
}

.link {
  color: white;
  font-weight: 500;

  &:hover {
    color: white;
  }
}

.linkwrapper {
  margin-bottom: 0.3rem;
}

.logowrapper {
  line-height: 0.8;
}

.logo {
  width: 200px;

  @include media-breakpoint-down(sm) {
    margin-top: 50px;
  }

  margin-bottom: 30px;
}

.footer .row {
  margin-top: 100px;
}

.service {
  padding: 0 100px 100px 100px;

  & > * {
    max-width: 100%;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 24px;
  }

  @include media-breakpoint-down(sm) {
    padding: 24px 24px;
  }
}

.footer h3 {
  color: #fff;
  margin-bottom: 20px;
}

.footer p span {
  display: block;
}
