@import 'assets/styles/responsive';

.image {
  top: 0;
  right: 25px;
  width: 430px;
  position: fixed;
  min-height: 100vh;
  background: url('/assets/images/not-found.jpg') no-repeat 44% 0 / cover;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.polygon {
  padding: 22px 0 0 32px;

  @include media-breakpoint-up(md) {
    padding-top: 66px;
  }

  @include media-breakpoint-up(lg) {
    bottom: 0;
    width: 430px;
    height: 214px;
    position: fixed;
    padding: 100px 0 0 24px;
    background: url('/assets/images/polygon.png');
    background-size: cover;
  }
}

.polygon svg {
  width: 140px;
}

.container {
  padding-top: 150px;
  padding-left: 50px;
}

.title {
  font-size: 22px;
  margin: 40px 0 0 0;
  font-weight: 500;
}

.code {
  font-size: 70px;
  font-weight: 800;
  letter-spacing: 7px;
  line-height: 70px;
  margin: 0 0 20px 0;
}

.button {
  display: block;
  margin: 20px 0;
}
