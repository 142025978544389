@import 'assets/styles/responsive';

.tbody tr:first-child td {
  border-top: none;
}

.tbody tr:first-child td:first-child {
  border-top-left-radius: $table-border-radius;
}

.tbody tr:first-child td:last-child {
  border-top-right-radius: $table-border-radius;
}
