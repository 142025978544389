@import 'assets/styles/variables';

.links {
  margin-left: auto;
}

.links button {
  padding: 0;
  border: none;
  cursor: pointer;
  font-size: 12px;
  color: $gray-600;
  background: none;
  line-height: 12px;
  display: inline-block;
}

.links button:hover {
  color: $gray-900;
  text-decoration: none;
}

.links button + button {
  margin-left: 16px;
}

.links .active,
.links .active:hover {
  color: $primary;
  text-decoration: underline;
}
