@import 'assets/styles/responsive';

.wrapper {
  background: rgba($input-border-color, 0.2);
  padding: 16px 10px 0;
  border-radius: 5px;
  margin-bottom: 7px;
}

.invalidated {
  background: rgba($red, 0.2);
}

.row {
  margin-left: 4px;
  margin-right: 4px;
  padding-right: 4px;
  position: relative;
}

.row [class^='col-'] {
  padding-left: 3px;
  padding-right: 3px;
}

.select {
  top: 5px;
  right: 2px;
  width: 30px;
  position: absolute;

  @media (min-width: 1200px) and (max-width: 1330px) {
    top: -3px;
  }
}

.beneficiary {
  font-size: 20px;
  font-weight: 500;
  margin-left: 4px;

  display: flex;
  align-items: center;
  margin-right: 25px;
  flex-wrap: wrap;
}

.female {
  color: $primary;
}

.male {
  color: $secondary;
}

.name {
  padding-left: 10px;
  word-break: break-word;
}

.birthday {
  padding-left: 10px;
  font-size: 15px;
  font-weight: 400;
  color: $gray-900;
}

.date {
  vertical-align: middle;
}

.description {
  color: $gray-900;
  font-size: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.summary strong {
  margin-bottom: 0;
}

.balanceWrapper strong {
  margin-bottom: 0;
}

.balance {
  padding-left: 0;
}

.coupon {
  font-size: 15px;
  &:hover {
    text-decoration: none;
  }
}

.input :global(.form-group) {
  margin-top: 24px;

  label {
    position: absolute;
    bottom: 100%;
  }
}

.isNew {
  border: 1px solid $green;
}

.tag {
  color: #fff;
  font-size: 15px;
  padding: 0 12px;
  margin-right: 10px;
  background: $green;
  border-radius: $border-radius;
}
