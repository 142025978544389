@import 'assets/styles/variables';

.banner {
  width: 100%;
  height: $top-banner-height + px;
  background-color: $primary;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.adminIcon {
  width: 60px;
}

.adminIconWrapper {
  padding: 0 0 0 50px;
}

.labelWrapper {
  padding: 0 20px 0 20px;
}

.adminLabel {
  color: $top-banner-text-color;
  font-size: 16px;
  font-weight: 500;
}

.displayUserName {
  color: $top-banner-text-color;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

// FIXME: hover doesn't do anything right now, remove !important
.stopButton {
  color: $top-banner-text-color;
  border-color: $top-banner-text-color;

  &:hover {
    background: transparent !important;
  }
}
