@import 'assets/styles/responsive';
@import 'assets/styles/animations';

.splash {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index-tooltip;
  position: fixed;
  background: #fff;
}

.logo {
  left: 64px;
  bottom: 64px;
  position: absolute;
}

.logo svg {
  width: 156px;
  fill: $primary;
}

.loading {
  top: 50%;
  left: 50%;
  position: absolute;
  margin: -40px 0 0 -40px;
}

.loading svg {
  width: 80px;
  height: 80px;
  animation: spin-around 1s infinite linear;
}
