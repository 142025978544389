@import 'assets/styles/responsive';

.inputArrow {
  right: 10px;
  width: 0;
  height: 0;
  bottom: -8px;
  position: absolute;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent $calculator-background-color transparent;
  margin: 0 auto;
}

.calculator {
  top: calc(100% + 7px);
  right: 0;
  max-width: 600px;
  color: #fff;
  z-index: $z-index-controls-top;
  font-size: 1rem;
  position: absolute;
  padding: 10px 10px 16px;
  border-radius: $input-border-radius;
  background: $calculator-background-color;
  width: auto;

  @include media-breakpoint-up(sm) {
    min-width: 400px;
  }
  @include media-breakpoint-up(lg) {
    min-width: 500px;
  }
  @include media-breakpoint-up(xl) {
    min-width: 600px;
  }
}

.calculator :global(.row) {
  margin-left: -4px;
  margin-right: -4px;
}

.calculator :global(.col) {
  padding-left: 4px;
  padding-right: 4px;
}

.calculator :global(.form-control) {
  border-color: #fff;
}

.calculator label {
  text-indent: 8px;
}

.desc {
  padding: 4px;
  margin-bottom: 0;
  white-space: normal;
}

.rightSideCalculator {
  left: 0;
}

.calculatorWrapper {
  :global(.input-group-text) {
    border-left: none;
  }
}
