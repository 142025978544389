@import 'assets/styles/variables';

.marketing {
  margin: 24px 0 0;
}

.check {
  color: $green;
  font-size: 24px;
  margin-right: 16px;
}

.message {
  margin: 0 32px 0 0;
}
