@import 'assets/styles/variables';

.card {
  margin-bottom: 48px;
  box-shadow: $card-box-shadow;

  :global {
    .card-header {
      padding-top: $card-header-spacer-y;
      padding-bottom: 0;
    }

    .card-header--between {
      padding-top: 0;
    }

    .card-header--with-line {
      &::before {
        content: '';
        display: block;
        border-top: 1px solid rgba($gray-600, 0.2);
        margin: 8px 0;
        left: -40px;
        top: -40px;
        position: relative;
        width: calc(100% + 80px);
      }
    }

    .card-header--with-icon i {
      display: block;
      line-height: 1;
      font-size: 48px;
      margin-bottom: 16px;
    }

    .card-body {
      padding: $card-spacer-y $card-spacer-x;
    }

    .card-body--between {
      padding-top: 0;
    }

    .card-separator {
      position: relative;
      margin: 0 -40px 40px;
      width: calc(100% + 80px);
      border-top: 1px solid rgba($gray-600, 0.2);
    }

    .card-title {
      margin-bottom: 0;
      color: $card-title-color;
    }

    .card-title-primary {
      margin-bottom: 0;
      color: $primary;
    }

    .card-subtitle {
      margin-top: 0;
      color: $gray-600;
      font-size: 14px;
    }

    .card-text {
      margin-top: 24px;
    }

    .card-footer {
      padding-top: 0;
    }
  }
}
