@import 'assets/styles/variables';

.advanced {
  display: none;
}

.toggler {
  padding: 0;
  border: none;
  color: $secondary;
  display: flex;
  font-size: 14px;
  cursor: pointer;
  background: none;
  position: relative;
  text-decoration: underline;
  outline: none !important;
  z-index: $z-index-normal;
}

.toggler i {
  padding-right: 8px;
}
