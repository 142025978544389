@import 'assets/styles/responsive';

.summary {
  padding-left: 16px;
  margin-bottom: 16px;

  @include media-breakpoint-down(md) {
    padding-left: 0;
  }
}

.balance {
  font-size: 15px;
}
