@import 'assets/styles/variables';

.title {
  color: $secondary;
}

.subtitle {
  font-size: 12px;
  margin: 10px 0;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.button {
  width: 100%;
  justify-content: flex-start;

  i {
    font-size: 1.25rem;
  }
}

.button span {
  text-align: left;
}

.errorMessage {
  color: $primary;
  font-size: 12px;
  font-weight: 500;
}

.downloadTableButton {
  &:not([disabled]) {
    &:hover span {
      color: #fff;
    }
  }

  :global(.form-group) {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
}

.form {
  :global {
    .Select-value {
      .Select-value-label {
        color: $secondary !important;
        font-weight: 500;
      }
    }
  }
}

.tableInput {
  [class*='inputWrapper'] {
    width: 55px;
  }
}
