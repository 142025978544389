@import 'assets/styles/responsive';

.buttons {
  flex-direction: row;
  justify-content: space-between;
  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }
}

.section {
  width: 48%;
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}
