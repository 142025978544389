@import 'assets/styles/responsive';

.column {
  padding-left: 454px;

  @include media-breakpoint-down(md) {
    padding-left: 24px;
  }
}

.section {
  margin: 0 auto;
  max-width: 700px;

  @include media-breakpoint-down(lg) {
    max-width: 600px;
  }

  @include media-breakpoint-down(md) {
    max-width: initial;
    padding: 0 32px;
  }

  @include media-breakpoint-down(md) {
    max-width: initial;
    padding: 0 8px;
  }
}

.section h1 {
  font-size: 56px;
  margin: 124px 0 64px;
  letter-spacing: -0.02em;

  @include media-breakpoint-down(md) {
    margin: 107px 0 33px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 36px;
    margin: 60px 0 40px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 24px;
  }
}
