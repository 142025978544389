@import 'assets/styles/variables';

$card-box-shadow-color: #000;
$card-box-shadow-color-opacity: 0.25;
$card-border-radius: 5px;

.card {
  width: 100%;
  padding: $card-padding 0;
  max-width: 100%;
  background: #fff;
  border-radius: $border-radius;
  position: relative;
}

.cardWithShadow {
  box-shadow: 0 3px 15px 0 rgba($card-box-shadow-color, $card-box-shadow-color-opacity);
}
