@import 'assets/styles/responsive';

.table {
  margin-bottom: 0;

  :global(.filled-row) {
    color: rgba($gray-600, 0.8);

    & td:not(:last-of-type),
    & td:not(:last-of-type) * {
      color: rgba($gray-600, 0.8);
    }
  }
}

.table thead th {
  border: none;
  font-weight: 400;
  padding: 0 24px 8px;
  font-size: $table-head-font-size;
}

.table tbody tr:first-child td {
  border-top: none;
}

.table tbody tr:first-child td:first-child {
  border-top-left-radius: $table-border-radius;
}

.table tbody tr:first-child td:last-child {
  border-top-right-radius: $table-border-radius;
}

.table tbody tr:last-child td:first-child {
  border-bottom-left-radius: $table-border-radius;
}

.table tbody tr:last-child td:last-child {
  border-bottom-right-radius: $table-border-radius;
}

.table td {
  line-height: 32px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

@include media-breakpoint-down(md) {
  .table td {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.table td > * {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.table td a:not(:global(.btn)) {
  color: $gray-900;
  text-decoration: underline;
}

.table td a:not(:global(.btn)):hover {
  color: $link-color;
}

.table tfoot td {
  border: none;
  font-weight: 500;
}
.table + .table {
  margin-top: 24px;
}

.scrollable {
  overflow-x: auto;
}
