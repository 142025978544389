@import 'assets/styles/responsive';

.form {
  display: flex;
  flex-direction: column;
}

.submit {
  margin-top: 32px;
  margin-left: auto;

  @include media-breakpoint-down(sm) {
    margin-left: 0;
  }
}

.alert {
  width: 100%;
  padding: 18px;
  border-radius: 8px;
  margin: 40px 0 54px;

  @include media-breakpoint-down(md) {
    margin: 74px 0 0;
    padding: 12px 18px;
  }

  @include media-breakpoint-down(sm) {
    margin: 48px 0 0;
  }
}

.error {
  background: rgba($red, 0.1);
}

.success {
  background: rgba($green, 0.1);
}

.loader {
  width: 36px;
  height: 36px;
  margin: 0 auto;
  border-radius: 50%;
  border: 0.125rem solid $pink;
  border-top-color: transparent;
  border-right-color: transparent;
  animation: spin-around 500ms infinite linear;
}

@keyframes spin-around {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.container {
  position: relative;
}

.link {
  font-size: 80%;
  margin-left: auto;
}
