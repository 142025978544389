@import 'assets/styles/variables';

.title {
  color: $secondary;
}

.button {
  display: flex;
  margin-left: auto;
}
