@import 'assets/styles/variables';
@import 'assets/styles/responsive';

.modalBackdrop {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-modal;
  position: fixed;
  background-color: $modal-backdrop-bg;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  position: relative;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.body {
  overflow: auto;
  max-height: calc(90vh - 148px);
}

.fitToContentWidth {
  display: inline-block;
}

@mixin mobile() {
  min-width: initial;
  max-width: 90%;
}

.small {
  max-width: 35%;
  min-width: 350px;

  @include media-breakpoint-down(sm) {
    @include mobile();
  }
}

.medium {
  max-width: 45%;
  min-width: 500px;

  @include media-breakpoint-down(sm) {
    @include mobile();
  }
}

.large {
  max-width: 90%;
}

.modalOpen {
  overflow: hidden;
}
