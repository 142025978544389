.label {
  flex: 0 0 250px;

  &:after {
    /* add ':' after label */
    content: ':';
  }
}

.highlight {
  font-weight: bold;
}
