@import 'assets/styles/variables';

.DateRangeBackdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index-forms;
  display: none;
  cursor: pointer;
  position: absolute;
  background: rgba($gray-900, 0.2);
  border-radius: $card-border-radius;
}

.DateRangeBackdrop--active {
  display: block;
}

a {
  overflow-wrap: break-word;
  word-wrap: break-word; // for IE
}
