@import 'assets/styles/variables';


.hoursWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  & > :first-child {
    margin: 0 5px 0 auto;
    width: 20%;
  }

  & > :last-child {
    margin: 0 auto 0 5px;
    width: 20%;
  }
}