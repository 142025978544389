@import 'assets/styles/responsive';

.controlsRow {
  position: absolute;
  top: 15px;
  left: 10px;
  right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-down(sm) {
    position: initial;
  }
}

.checkbox {
  line-height: 1;
  display: flex;
}

.wrapper {
  position: relative;
  top: 11px;
}

.header {
  display: flex;
  align-items: center;
}

.birthday {
  line-height: normal;
}

.date {
  vertical-align: middle;
}

.tile {
  padding: 15px 110px 15px 50px;
  border-radius: 0;
  border-color: $gray-light;
  border-top: 0;
  border-right: 0;
  border-left: 0;

  @include media-breakpoint-down(sm) {
    padding: 15px;
  }
}

.even {
  background-color: $table-accent-bg;
}

.first {
  border-radius: 8px 8px 0 0;
}

.last {
  border: none;
  border-radius: 0 0 8px 8px;
}

.badge {
  position: absolute;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $red;
  border-radius: 5px;
  color: $white;
  padding: 2px 4px;
  font-size: 10px;
  height: 15px;
}

.invalidated {
  color: $red;
}
