.readOnly {
  padding-top: 0;
  padding-bottom: 5px;
}

.horizontalLayoutItem {
  .wrapper {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }

  .inputWrapper {
    width: 100%;
    max-width: none;
    margin-top: 10px;
    padding-left: 0;
    padding-right: 0;
  }
}