@import 'assets/styles/responsive';

.actionList {
  max-width: 535px;
  margin: 40px 0;
}

.action {
  display: flex;
  justify-content: space-between;
  height: 48px;

  padding: 0px 18px;
  border-top: $table-border-width solid $table-border-color;
  align-items: center;

  &:last-child {
    border-bottom: $table-border-width solid $table-border-color;
  }
}
