@import 'assets/styles/responsive';

.thead {
}

.cell {
  &:first-child {
    padding-left: 24px;
  }

  &:last-child {
    padding-right: 24px;
  }
}
