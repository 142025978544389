@import 'assets/styles/responsive';

.title {
  font-size: 56px;
  font-weight: $auth-title-font-weight;
  font-family: $auth-title-font-family;
  letter-spacing: $auth-title-letter-spacing;
  margin: 124px 0 64px;

  @include media-breakpoint-down(md) {
    margin: 107px 0 33px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 36px;
    margin: 60px 0 40px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 24px;
  }
}

.withBackOption {
  @include media-breakpoint-down(md) {
    margin: 0 0 33px;
  }
}
