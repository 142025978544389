.fields {
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: auto;

  grid-template-columns: max-content 1fr;
}

.fieldLabel {
  grid-column: 1;
  line-height: 14px;

  &:after {
    /* add ':' after label */
    content: ':';
  }

  margin-right: 40px;
  margin-bottom: 5px;
}

.fieldValue {
  grid-column: 2;
  line-height: 14px;

  &.bold {
    font-weight: bold;
  }
}
