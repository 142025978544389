//
// standard
//

$z-index-background: -1;
$z-index-controls: 999;
$z-index-controls-top: 1000;
$z-index-forms: 2;
$z-index-modal: 1040;
$z-index-normal: 1;
$z-index-notifications: 1041;
$z-index-tooltip: 1071;
$z-index-zero: 0;
//
// Color system
//

$white: #fff;
$gray-100: #f2f2f2;
$gray-200: #d4d4d4;
$gray-300: #bdbdbd;
$gray-600: #c8c8c8;
$gray-700: #878787;
$gray-800: #4c4c4c;
$gray-900: #555;
$black: #000;

$purple: #5e34e5;
$pink: #e717aa;
$red: #ff3333;
$green: #80ba99;
$gray-light: #a6a39a;
$gray: #5a5856;
$gray-dark: #2f2e2e;

$primary: #ff5400;
$secondary: #00c3a5;

$success: #6ebe97;
$failure: #fc595e;

$warning: #ff931e;
//
// App theme
//

$company: 'vaana';
$sidebar-width: 312px;
$sidebar-background: rgba(#c8c8c8, 0.2);
$right-sidebar-background: #e4f6f0;
$sidebar-user-heading-color: $gray-900;
$sidebar-user-color: $primary;
$sidebar-profile-btn-border: $primary;
$sidebar-title-color: $gray-700;
$sidebar-title-font-size: 14px;
$sidebar-group-border-color: $secondary;
$sidebar-link-color: rgba(#032823, 0.8);
$sidebar-link-hover: #032823;
$sidebar-link-active-color: $secondary;
$sidebar-link-active-background: $white;
$sidebar-icon-color: rgba(#032823, 0.8);
$sidebar-business-border-color: $gray-600;
$sidebar-business-border-hover: $gray-600;
$sidebar-business-background-hover: rgba($gray-600, 0.2);
$sidebar-business-background-active: rgba($gray-600, 0.2);
$sidebar-business-active-color: $primary;
$sidebar-business-background-inactive: rgba($gray-300, 0.2);
$sidebar-business-inactive-color: rgba($primary, 0.4);
$sidebar-opened-color: $secondary;
$sidebar-nested-back-color: $gray-900;
$sidebar-nested-back-icon-color: $primary;
$sidebar-nested-nav-background: #00c3a5;
$sidebar-nested-heading-color: $white;
$sidebar-nested-nav-color: $white;
$sidebar-nested-nav-border: false;
$sidebar-shortcuts-heading-color: $gray-700;
$sidebar-shortcuts-heading-icon: $primary;
$sidebar-shortcuts-color: #032823;
$sidebar-shortcuts-icon: $primary;
$breadcrumb-color: $gray-900;
$breadcrumb-link-color: $primary;
$breadcrumb-background: #f6f6f6;
$breadcrumb-hover-bg: darken($breadcrumb-background, 2%);
$card-header-spacer-y: 0;
$card-title-color: $secondary;
$table-border-radius: 8px;
$table-head-font-size: 14px;
$tile-bg: rgba($gray-600, 0.08);
$tile-border: 1px rgba($gray-600, 0.2) solid;
$reverse-secondary-btn: true;
$white-button-color: $white;
$white-button-background: $secondary;
$select-color: $gray-900;
$modal-title-color: $primary;
$modal-subtitle-color: $gray-700;
$modal-divider-color: $gray-600;
$radio-border-color: $gray-600;
$radio-active-color: $secondary;
$file-upload-bg: $primary;
$input-icon-color: $secondary;
$input-info-icon: 'assets/images/info.png';
$datepicker-icon-color: $secondary;
$auth-btn-hover-bg: rgba($gray-600, 0.2);
$auth-btn-hover-color: $primary;
$summary-field-color: $gray;
$calendar-backdrop-top: -18px;
$calendar-backdrop-bottom: 20px;
$calendar-day-color: $gray-700;
$card-box-shadow: none;
$calculator-background-color: $secondary;
$action-placeholder-bg: $primary;
$btn-link-color: $secondary;
$register-icons-color: $gray-600;
$return-background-color: $secondary;
$return-border-color: $secondary;
$return-fee-color: $white;
$return-icon-color: $white;
$card-padding: 24px;

// Top bar/banner (e.g. impersonation banner)
$top-banner-text-color: $white;
// Top banner height in px. This value is used in SCSS and JS, which is why the px is not added here
$top-banner-height: 80;

// Benefit calculator
$benefit-calc-bg: $secondary;
$benefit-calc-color: $white;

// Option
$option-color: $primary;
$option-border-color: $primary;
$option-active-bg: $primary;
$option-active-color: $white;
$option-active-border-width: 1px;
$option-active-border-color: $primary;
$option-active-radio-color: $white;
$option-disabled-bg: $gray-100;

// Light button
$light-btn-border-color: $gray-600;
$light-btn-active-bg: $gray-100;

// Auth title
$auth-title-font-family: 'Rubik', sans-serif;
$auth-title-letter-spacing: -0.02em;
$auth-title-font-weight: 500;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 9%;

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $gray-900;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
);

// Links
//
// Style anchor elements.

$link-color: $primary;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 48px;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5;
$line-height-sm: 1.5;

$border-width: 1px;

$border-radius: 5px;
$border-radius-lg: 8px;
$border-radius-sm: 2px;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Rubik', sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem;
$font-size-lg: 1rem;
$font-size-sm: 0.875rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;

$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-line-height: 1.1;
$headings-color: $gray-900;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 12px;

$table-bg: transparent;
$table-row-bg: rgba($gray-600, 0.08);
$table-accent-bg: rgba($gray-600, 0.16);

$table-border-width: 1px;
$table-border-color: $gray-light;

$table-head-color: $gray-900;

// stylelint-disable
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
  ),
  $spacers
);

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 0.75rem;
$input-btn-line-height: 1.25;

$input-btn-focus-width: 0.2rem;

$input-btn-padding-y-lg: 16px;
$input-btn-padding-x-lg: 24px;
$input-btn-line-height-lg: 1.5;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-focus-width: $input-btn-focus-width;
$btn-text-transform: none;
$btn-lg-padding: 17px 32px;
$btn-md-padding: 14px 40px;
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius-lg;
$btn-border-radius-sm: $border-radius-sm;

// Forms
$input-bg: $white;

//inputs custom
$input-disabled-bg: rgba($gray-600, 0.2);
$input-disabled-color: rgba($gray-900, 1);

$input-margin: 0;
$label-side-margin: 1rem;

$input-y-padding: 9px;
$input-x-padding: 16px;
$input-icon-padding: 40px;

$input-padding: $input-y-padding $input-x-padding;
//end inputs custom

$input-color: $gray-900;
$input-border-color: #c8c8c8;
$input-btn-border-width: $border-width;

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;

$input-border-size: 1px;

//input focus
$input-focus-bg: $input-bg;
$input-focus-border-color: $secondary;
$input-focus-box-shadow: inset 0 0 0 1px $input-focus-border-color;
$input-focus-color: $input-color;

//input invalid
$input-invalid-bg: $input-bg;
$input-invalid-border-color: $failure;
$input-invalid-box-shadow: inset 0 0 0 1px $input-invalid-border-color;

$input-placeholder-color: $gray-300;

$input-height-border: $input-btn-border-width * 2 !default;

$form-group-margin-bottom: 1rem !default;

$input-height-inner: 40px;
$input-height: calc(#{$input-height-inner} + #{$input-height-border});

//$input-height-inner-sm:         ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
//$input-height-sm:               calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;

$input-height-inner-lg: 48px;
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border});

//$input-group-addon-color:        $input-color !default;
$input-group-addon-bg: $input-bg;
$input-group-addon-border-color: $input-border-color;
$input-group-btn-border-color: $input-border-color;

// Cards

$card-spacer-y: 40px;
$card-spacer-x: 16px;
$card-border-width: 0;
$card-border-radius: 5px;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg: transparent;
$card-bg: transparent;

//$card-img-overlay-padding: 1.25rem !default;

//$card-deck-margin:          ($grid-gutter-width / 2) !default;

//$card-columns-count:        3 !default;
//$card-columns-gap:          1.25rem !default;
//$card-columns-margin:       $card-spacer-y !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 32px 40px 24px;

$modal-dialog-margin: 0px;
$modal-dialog-margin-y-sm-up: 64px;
$modal-dialog-margin-x-sm-up: 64px;

$modal-title-line-height: 28px;

$modal-content-bg: $white;
$modal-content-border-width: 0;

$modal-backdrop-bg: rgba($gray-900, 0.7);
$modal-backdrop-opacity: 1;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding: 40px 40px 0;

$modal-lg: 800px !default;
$modal-md: 768px;
$modal-sm: 300px !default;

$modal-transition: transform 0.3s ease-out !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: 2rem;
$alert-padding-x: 2rem;
$alert-margin-bottom: 1.5rem;
$alert-border-radius: 0.5rem;
$alert-link-font-weight: 500;
$alert-border-width: 0;

///CONTROLS
$control-small-size: 32px;
$control-regular-size: 40px;
$control-auto-size: auto;

// Terms markdown
$terms-header-medium: 1.25rem;
$terms-header-large: 1.5rem;

:export {
  topBannerHeight: $top-banner-height;
}
