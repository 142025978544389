@import 'assets/styles/variables';

$width: 24px;
$height: 24px;
$border-radius: 50%;
$checked-fill-ratio: 0.7;

.wrapper {
  position: relative;
}

.input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.radio {
  align-items: center;
  background: #fff;
  border-radius: $border-radius;
  border: 1px $radio-border-color solid;
  cursor: pointer;
  display: flex;
  height: $height;
  justify-content: center;
  width: $width;
}

.checked {
  background: transparent;
  border-radius: $border-radius;
  width: floor($width * $checked-fill-ratio);
  height: floor($height * $checked-fill-ratio);
}

.input:checked + .radio {
  border-color: $secondary;

  & > .checked {
    background: $secondary;
  }
}

//invalid
:global(.invalid) {
  .radio {
    border-color: $input-invalid-border-color;
  }

  .input:checked + .radio {
    border-color: $input-invalid-border-color;

    & > .checked {
      background: $input-invalid-border-color;
    }
  }
}

//disabled
:global(.disabled) {
  .radio {
    border-color: $input-disabled-color;
  }

  .input:checked + .radio {
    border-color: $input-disabled-color;

    & > .checked {
      background: $input-disabled-color;
    }
  }
}
