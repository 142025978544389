/*
    Flaticon icon font: Flaticon
    Creation date: 28/08/2019 12:48
    */

@font-face {
  font-family: 'Flaticon';
  src: url('../fonts/Flaticon/Flaticon.eot');
  src: url('../fonts/Flaticon/Flaticon.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Flaticon/Flaticon.woff') format('woff'), url('../fonts/Flaticon/Flaticon.ttf') format('truetype'),
    url('../fonts/Flaticon/Flaticon.svg#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Flaticon';
    src: url('../fonts/Flaticon/Flaticon.svg#Flaticon') format('svg');
  }
}

.link {
  cursor: pointer;
}

.fi:before {
  display: inline-block;
  font-family: 'Flaticon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.fi-checkbox:before {
  content: '\f100';
}
.fi-time:before {
  content: '\f101';
}
.fi-history:before {
  content: '\f102';
}
.fi-computer:before {
  content: '\f103';
}
.fi-language:before {
  content: '\f104';
}
.fi-discount-voucher:before {
  content: '\f105';
}
.fi-interface:before {
  content: '\f106';
}
.fi-calculator:before {
  content: '\f107';
}
.fi-business:before {
  content: '\f108';
}
.fi-commerce:before {
  content: '\f109';
}
.fi-coin:before {
  content: '\f10a';
}
.fi-technology:before {
  content: '\f10b';
}
.fi-profile:before {
  content: '\f10c';
}
.fi-declined:before {
  content: '\f10d';
}
.fi-credit-card:before {
  content: '\f10e';
}
.fi-payment-method:before {
  content: '\f10f';
}
.fi-settlements:before {
  content: '\f110';
}
.fi-switch-company:before {
  content: '\f111';
}
.fi-desktop-payment:before {
  content: '\f112';
}
.fi-transactionhistory:before {
  content: '\f113';
}
.fi-subscription:before {
  content: '\f114';
}
.fi-order-vouchers:before {
  content: '\f115';
}
.fi-shortcuts:before {
  content: '\f116';
}
.fi-addservicecoupontype:before {
  content: '\f117';
}
.fi-renewservicecoupon:before {
  content: '\f118';
}
.fi-sharedservicecoupon:before {
  content: '\f119';
}
.fi-shareservicecoupon:before {
  content: '\f11a';
}
.fi-municipalhistory:before {
  content: '\f11b';
}
.fi-female:before {
  content: '\f11c';
}
.fi-check:before {
  content: '\f11d';
}
.fi-hand-graving-smartphone:before {
  content: '\f11e';
}
.fi-qr-code:before {
  content: '\f11f';
}
.fi-spinner-of-dots:before {
  content: '\f120';
}
.fi-correct-symbol:before {
  content: '\f121';
}
.fi-power-button-off:before {
  content: '\f122';
}
.fi-xlsx-file-format-extension:before {
  content: '\f123';
}
.fi-multiple-users-silhouette:before {
  content: '\f124';
}
.fi-stack:before {
  content: '\f125';
}
.fi-left-arrow-circular:before {
  content: '\f126';
}
.fi-shopping-cart:before {
  content: '\f127';
}
.fi-go-back-left-arrow:before {
  content: '\f128';
}
.fi-history-clock-button:before {
  content: '\f129';
}
.fi-right-arrow-forward:before {
  content: '\f12a';
}
.fi-pencil-edit-button:before {
  content: '\f12b';
}
.fi-signing-the-contract:before {
  content: '\f12c';
}
.fi-sharing-interface:before {
  content: '\f12d';
}
.fi-home-icon-silhouette:before {
  content: '\f12e';
}
.fi-man-user:before {
  content: '\f12f';
}
.fi-account-balance:before {
  content: '\f130';
}
.fi-instagram-logo:before {
  content: '\f131';
}
.fi-statistics:before {
  content: '\f132';
}
.fi-sort:before {
  content: '\f133';
}
.fi-pdf-booklet:before {
  content: '\f134';
}
.fi-pdf:before {
  content: '\f135';
}
.fi-male:before {
  content: '\f136';
}
.fi-arrow-right:before {
  content: '\f137';
}
.fi-minus:before {
  content: '\f138';
}
.fi-plus:before {
  content: '\f139';
}
.fi-arrow-left:before {
  content: '\f13a';
}
.fi-arrow-down:before {
  content: '\f13a';
  transform: rotate(270deg);
}
.fi-arrow-up:before {
  content: '\f13a';
  transform: rotate(90deg);
}
.fi-charge:before {
  content: '\f13b';
}
.fi-download-excel:before {
  content: '\f13c';
}
.fi-renew:before {
  content: '\f13d';
}
.fi-open:before {
  content: '\f13e';
}
.fi-printer:before {
  content: '\f13f';
}
.fi-search:before {
  content: '\f140';
}
.fi-garbage:before {
  content: '\f141';
}
.fi-remove-bold:before {
  content: '\f142';
}
.fi-copy:before {
  content: '\f143';
}
.fi-download-summary:before {
  content: '\f144';
}
.fi-download-list:before {
  content: '\f145';
}
.fi-details:before {
  content: '\f146';
}
.fi-download-voucher-table:before {
  content: '\f147';
}
.fi-remove:before {
  content: '\f148';
}
.fi-download-voucher:before {
  content: '\f149';
}
.fi-error:before {
  content: '\f14a';
}
.fi-pen-disabled:before {
  content: '\f14b';
}
.fi-menu:before {
  content: '\f14c';
}
.fi-add:before {
  content: '\f14d';
}
.fi-alert:before {
  content: '\f14e';
}
.fi-euro-symbol:before {
  content: '\f14f';
}
.fi-cake:before {
  content: '\f150';
}
.fi-info-sign:before {
  content: '\f151';
}
.fi-expand:before {
  content: '\f152';
}
