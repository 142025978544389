@import 'assets/styles/variables';

.columns {
  padding-left: 8px;
}

.columns i {
  color: $secondary;
}

.datesField {
  min-width: 250px;
}

.submit {
  width: 100%;
  margin-top: 22px;
}
