@import 'assets/styles/variables';

.table {
  font-size: 12px;
  table-layout: fixed;

  thead th {
    text-align: center;
    padding: 4px 2px;
  }

  tbody td {
    padding: 4px 2px;
  }

  tbody td:last-of-type {
    padding-right: 2px;
  }

  tbody td strong {
    display: block;
    min-height: 20px;
    line-height: 20px;
  }
}
