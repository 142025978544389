@import 'assets/styles/variables';

.content {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}

.icon {
  font-size: 50px;
  margin: 0 25px 0 0;
  line-height: 50px;
}

.iconSuccess {
  color: $success;
}

.iconFailure {
  color: $failure;
}
