@import 'assets/styles/variables';

.button {
  margin: ($card-spacer-x * 0.5) 0;
  width: 100%;
  position: relative;

  i {
    position: absolute;
    top: 50%;
    left: $card-spacer-x * 0.5;
    transform: translateY(-50%);
  }
}
