@import 'assets/styles/variables';

.content {
  width: 100%;
  display: flex;
  padding: 0 $card-padding;
  max-width: 100%;
  position: relative;
  margin: 0 auto;
  flex-direction: column;

  & > * {
    -ms-flex: 0 1 auto;
  }
}
