@import 'assets/styles/variables';
@import 'assets/styles/select';

$select-font-size: 0.875rem;
$select-text-selected-color: $white;
.select {
  padding: 0;
  border: 0;

  &:global(.Select) {
    width: 100%;
    flex: 1;

    //transparent background for visible box-shadow from input-base
    &:global(.is-focused),
    &:global(.is-open) {
      :global(.Select-control) {
        background: transparent;
      }
    }

    // fix for react select styling
    &:global(.is-disabled) :global(.Select-control) {
      background: none;
      :global(.Select-value) > :global(.Select-value-label) {
        color: inherit;
      }
    }
  }

  &:global(.Select),
  :global(.Select-control),
  :global(.Select-multi-value-wrapper) {
    background: transparent;
    box-shadow: none;
    height: 100%;
    position: relative;
    flex: 1 1 auto;
  }

  &:global(.Select--multi) {
    :global(.Select-multi-value-wrapper) {
      flex-wrap: wrap;
      min-height: $control-regular-size;
      padding-bottom: 5px;
    }
  }

  :global(.Select-placeholder) {
    height: 100%;
    background: transparent;
    box-shadow: none;
  }

  :global(.Select-value-label) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  :global(.Select-control) {
    border: 0;
    background: transparent;
    display: inline-flex;
  }

  :global(.Select-placeholder),
  :global(.Select-value) {
    display: inline-flex;
    align-items: center;
    line-height: initial;
    width: 100%;
  }

  :global(.Select-placeholder),
  &:global(.Select--single) > :global(.Select-control) :global(.Select-value) {
    line-height: initial;
  }

  :global(.Select-multi-value-wrapper) {
    display: flex;
    align-items: center;
    max-width: calc(100% - 25px);
  }

  :global(.Select-value) {
    font-size: $select-font-size;
  }

  & :global(.Select-input) {
    height: auto;

    & > input {
      padding: 0;
    }
  }

  //multi value wrapper
  &:global(.Select--multi) {
    :global(.Select-value) {
      background: rgba($secondary, 0.1);
      color: $input-color;
      border: 1px solid rgba($secondary, 0.1);
    }

    :global(.Select-value-icon) {
      color: $secondary;
      border-right: 1px solid rgba($secondary, 0.1);

      &:hover {
        background: rgba($secondary, 0.3);
      }
    }
  }

  //option hover
  & :global(.Select-option) {
    background: transparent;
    border-radius: 0;

    &:global(.is-focused) {
      background: rgba($secondary, 0.1);
    }
    &:global(.is-selected) {
      background: $secondary;
      color: $select-text-selected-color;
    }

    &:global(.is-disabled) {
      font-size: 12px;
      letter-spacing: normal;
      background: $right-sidebar-background;
      color: $gray-800;

      &:not(.disabled-option) {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
      }
    }
  }

  & :global(.Select-menu-outer) {
    border-color: $secondary;
    border-width: 2px;
    border-top-width: 1px;
    z-index: 100;
  }

  & :global(.Select-control) :global(.Select-input):focus {
    background: transparent;
  }

  & :global(.Select-arrow-zone) {
    display: flex;
    align-items: center;
  }

  &:global(.Select--single) {
    & :global(.Select-value) {
      position: relative;
    }

    & :global(.Select-input) {
      position: absolute;
    }
  }
}

//primary theme
.select:global(.theme-primary) {
  :global(.Select-value-label) {
    color: $primary !important;
  }

  :global(.Select-arrow) {
    border-top-color: $primary;
  }

  :global(.Select-option) {
    &:global(.is-focused) {
      background: rgba($primary, 0.1);
    }

    &:global(.is-selected) {
      background: $primary;
      color: #fff;
    }
  }

  //multi value wrapper
  &:global(.Select--multi) {
    :global(.Select-value) {
      background: rgba($primary, 0.1);
      color: $input-color;
      border: 1px solid rgba($primary, 0.1);
    }

    :global(.Select-value-icon) {
      color: $primary;
      border-right: 1px solid rgba($primary, 0.1);

      &:hover {
        background: rgba($primary, 0.3);
      }
    }
  }

  &.loading {
    & :global(.Select-arrow),
    & :global(.Select-arrow-zone:hover) > :global(.Select-arrow) {
      border: 0.125rem solid $primary;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }
}

//secondary theme
.select:global(.theme-secondary) {
  &.loading {
    & :global(.Select-arrow),
    & :global(.Select-arrow-zone:hover) > :global(.Select-arrow) {
      border: 0.125rem solid $secondary;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }
}

.disabled-option {
  background-color: $option-disabled-bg !important;
}

@keyframes spin-around {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading {
  & :global(.Select-arrow),
  & :global(.Select-arrow-zone:hover) > :global(.Select-arrow) {
    content: '';
    width: 1rem;
    height: 1rem;
    display: block;
    margin-top: -0.5rem;
    margin-left: -0.5rem;
    position: absolute;
    top: 19px;
    animation: spin-around 500ms infinite linear;
    border-radius: 290486px;
  }
}

// When using OSX, the dropdown scrollbar is not visible unless it's being used,
// so it's easy to not realize that there are more options available if you scroll.
// This is a workaround to always show the scrollbar on OSX.
.osx {
  & :global(.Select-menu-outer) > ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  & :global(.Select-menu-outer) > ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.optionlabel {
  padding-right: 23px;
}
