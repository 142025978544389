@import 'assets/styles/variables';

.textarea {
  resize: none;
}

.charCounter {
  text-align: right;
}

.append {
  margin-top: auto;
}

.icon {
  margin-top: 4px;
  margin-bottom: auto;
}

.disabledIcon {
  position: absolute;
  top: 8px;
  left: 12px;
}

.disabled {
  text-indent: 20px;
}
