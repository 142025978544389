@import 'assets/styles/responsive';
@import 'assets/styles/variables';

$width: 30px;
$height: 30px;

.input {
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: $z-index-normal;
  width: $width;
  height: $height;
}

.checkbox {
  width: $width;
  height: $height;
  cursor: pointer;
  flex: 0 0 30px;

  &::before {
    align-items: center;
    border-radius: 4px;
    border: 1px rgba($secondary, 0.6) solid;
    content: '';
    display: flex;
    height: $height;
    justify-content: center;
    position: relative;
    width: $width;
  }

  & > i {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: $secondary;
  }
}

.input:checked + .checkbox > i {
  display: flex;
}

//invalid
:global(.invalid) {
  .checkbox {
    &::before {
      border-color: $input-invalid-border-color;
    }

    & > i {
      color: $input-invalid-border-color;
    }
  }
}

//disabled
:global(.disabled) {
  .checkbox {
    &::before {
      border-color: $input-disabled-color;
    }

    & > i {
      color: $input-disabled-color;
    }
  }

  .labelChecked > span {
    color: $input-disabled-color;
  }
}

.labelChecked > span {
  color: $secondary;
}
