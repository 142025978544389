.modal {
  display: block;
}

.modalBody {
  padding: 0;
}

.modalContent {
  padding: 60px;
  text-align: center;
}

.modalFooter {
  width: auto;
  margin: 40px auto 0;

  > * {
    margin: 0;

    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}

.button {
  min-width: 100px;
}
