@import 'assets/styles/variables';

.content {
  width: calc(100% - 260px);
  max-width: 900px;
}

.title {
  width: 260px;
  font-weight: 400;
}
