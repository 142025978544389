@import 'assets/styles/variables';

.button {
  display: block;
  width: 100%;
  text-align: left;
  left: 11px;
}

.form {
  :global(.input-group.disabled .input-group-append .input-group-text) {
    background-color: rgba($gray-600, 0.2);
    border-left: 0;
  }

  :global(.input-group.disabled .input-group-append) {
    margin-left: 0;
  }
}

.errorMessage {
  color: $primary;
  font-size: 12px;
  font-weight: 500;
}
