@import 'assets/styles/variables';

.tile {
  width: 100%;
  flex-grow: 1;
  padding: 24px;
  border-radius: 8px;
  background: $tile-bg;
  border: $tile-border;

  &:global(.filled-tile) {
    &:nth-child(n) {
      color: rgba($gray-600, 0.8);

      & *:not(:global(.btn)) {
        color: inherit;
      }
    }
  }

  small {
    display: block;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 4px;
  }

  :global {
    .row {
      margin: 0 -12px;
      line-height: 18px;
    }

    [class^='col'] {
      margin-top: 16px;
      padding-left: 12px;
      padding-right: 12px;

      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
    }

    .buttons {
      margin-top: 22px;

      * + * {
        margin-left: 8px;
      }
    }
  }
}
