@import 'assets/styles/responsive';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.header {
  white-space: nowrap;
}

.fields {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reference {
  margin-left: auto;

  @include media-breakpoint-down(lg) {
    margin-left: 0;
  }
}
