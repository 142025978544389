@import 'assets/styles/responsive';

.main {
  min-height: 100vh;
  background: $white;
  position: relative;
  padding-bottom: 394px;
  padding-top: 4rem;
  overflow: hidden;

  @include media-breakpoint-up(sm) {
    padding-top: 0;
    overflow: auto;
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 0;
  }
}

@include media-breakpoint-down(xs) {
  :global(.sidebar-open) {
    overflow: hidden;
  }
}

@include media-breakpoint-up(lg) {
  :global(.sidebar-open) .container {
    max-width: calc(100% - 312px);
  }
}

:global(.nav-open) .container::after {
  top: 0;
  right: 0;
  bottom: 0;
  left: -1px;
  z-index: 75;
  content: '';
  display: block;
  position: absolute;
  background: $modal-backdrop-bg;
}
