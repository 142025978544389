.container {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

.label {
  margin-right: 10px;
}

.select {
  width: 160px;
}

.option {
  display: flex;
}

.flag {
  width: 16px;
  height: 16px;
  margin: 2px 12px 0 0;
  background: 0 0 / cover no-repeat;
}

.en {
  background-image: url(/assets/images/flags/en.png);
}

.fi {
  background-image: url(/assets/images/flags/fi.png);
}

.sv {
  background-image: url(/assets/images/flags/sv.png);
}
