@import '../../../assets/styles/responsive';

@include media-breakpoint-down(md) {
  .header {
    display: flex;
    flex-flow: column;
  }

  .header :global(.btn) {
    margin-top: 16px;
    align-self: flex-start;
  }
}

.top {
  display: flex;
  min-height: 62px;
  align-items: center;
  justify-content: flex-end;
}

.backButton {
  margin-right: 10px;
}
