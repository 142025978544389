@import 'assets/styles/variables';

.returnedLabel {
  color: $gray-700;
}

.service {
  max-width: 250px;

  @media (min-width: 1200px) and (max-width: 1350px) {
    max-width: 150px;
  }
}
