@import 'assets/styles/variables';
@import 'assets/styles/responsive';

.wrapper {
  display: flex;
  flex-direction: column;
  background: $sidebar-background;
  padding: ($card-spacer-x * 0.5) $card-spacer-x ($card-spacer-x * 1.5);
  border-radius: $border-radius;
  width: 400px;
  max-width: 100%;
}

.title {
  font-size: 12px;
  line-height: 18px;
  flex: 1 0 auto;
  margin: 8px 0 4px $card-spacer-x;
}

.container {
  display: flex;
  flex: 1 0 auto;
  padding: 0 $card-spacer-x 11px;
  background: white;
  border-radius: $border-radius;
  flex-wrap: wrap;
  border: 0.5px solid $gray-light;

  @include media-breakpoint-down(sm) {
    padding: 0 $card-spacer-x/2 6px;
  }
}

.row {
  display: flex;
  flex: 1 0 100%;
  justify-content: space-between;
  line-height: 24px;
  margin: 11px 0 0;
}

.bold {
  font-weight: bold;
}

.colorSecondary {
  color: $secondary;
}
