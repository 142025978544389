@import 'assets/styles/variables';
@import 'assets/styles/select';

.selectCard {
  padding: $card-spacer-x;
  flex: 1 1 auto;
}

.selectWrapper {
  display: flex;
  width: $sidebar-width;
  max-width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.selectContainer {
  flex: 1 0 auto;
  min-width: 110px;
  padding: 0 10px 10px 0;
}

.inputContainer {
  max-width: $sidebar-width * 2;
}

:global(.fi-search) {
  font-size: 1.1rem;
}

.title {
  padding: $card-spacer-x * 1.5 0 $card-spacer-x 0;
}
