@import 'assets/styles/variables';

.body {
  h2,
  h3 {
    font-size: $terms-header-medium;
    color: $primary;
  }

  h2 {
    font-size: $terms-header-large;
  }
}
