@import 'assets/styles/variables';

.notification {
  position: relative;
  transform: translate3d(100%, 0, 0);
  transition: all 300ms ease-in-out;
  opacity: 0;
  margin-bottom: 10px;
  height: 100%;
  align-self: flex-end;
  width: auto;
}

.notification.visible:hover {
  transform: translate3d(-1%, 0, 0);
}

.visible {
  transform: translate3d(0%, 0, 0);
  opacity: 1;
}

.close {
  cursor: pointer;
  margin-left: 10px;
}

.message {
  word-break: break-word;
}

.info {
}

.failure {
  background-color: $failure;
  color: $white;
}

.success {
  background-color: $success;
  color: $white;
}

.pending {
  background-color: $warning;
  color: $white;
}
