@import 'assets/styles/responsive';

.back {
  font-size: 20px;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}

.primary {
  color: $primary;
}

.secondary {
  color: $secondary;
}

.button {
  border: 0;
  cursor: pointer;
  background: transparent;
}
