@import 'assets/styles/variables';

$green: #097969;

.textAdded {
  padding: 0 2px;
  color: white;
  background-color: $green;
}

.textRemoved {
  color: $red;
  text-decoration: line-through;
}

.text {
  white-space: pre-wrap;
}
