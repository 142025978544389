@import 'assets/styles/variables';

.pending {
  td,
  i {
    color: rgba($gray-900, 0.5) !important;
  }
}

.icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * + * {
    margin-left: 8px;
  }
}
