@import 'assets/styles/variables';

.issuerColumn {
  min-width: 140px;
}

.serviceProducedColumn {
  min-width: 130px;
}

.code {
  width: 140px;
}
