@import 'assets/styles/variables';
@import 'assets/styles/responsive';

.wrapper {
  column-gap: 10px;
  columns: 2;
  display: block;

  @include media-breakpoint-up(md) {
    columns: 3;
  }

  @include media-breakpoint-up(lg) {
    columns: 5;
  }
}

.item {
  margin-bottom: 10px;
}
