.buttons {
  text-align: right;

  a + a {
    margin-left: 8px;
  }
}

.type {
  width: 10%;
}

.title {
  width: 17%;
}

.description {
  width: 30%;
}
