.column {
  min-width: 200px;
  text-align: right;

  &.label {
    min-width: 350px;
    text-align: left;
  }
  &.innerRow {
    padding-left: 36px !important;
  }
}
