@import 'assets/styles/responsive';
@import 'assets/styles/variables';

.panel {
  width: 300px;
  min-width: 300px;
  position: absolute;
  right: 65px;
  top: 0;
  z-index: $z-index-notifications;

  @include media-breakpoint-up(md) {
    width: 600px;
  }

  @include media-breakpoint-up(lg) {
    width: 900px;
  }
}

.toggleContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  min-width: 120px;
  margin-bottom: 0px;
}
