@import 'assets/styles/responsive';

.container {
  margin: 10px 40px;
}

.header {
  display: flex;
  margin: 8px 0;
  align-items: center;

  @include media-breakpoint-down(sm) {
    flex-flow: column;
  }
}

.header h4 {
  margin-bottom: 0;
  color: $modal-title-color;
}

.type {
  color: #ffffff;
  font-size: 0.85rem;
  padding: 0 8px;
  line-height: 24px;
  margin-left: 16px;
  border-radius: 16px;
  text-align: center;

  @include media-breakpoint-down(sm) {
    margin: 8px auto;
  }
}

.positive {
  background: rgba($green, 0.95);
}

.negative {
  background: rgba($red, 0.95);
}

.date {
  color: $gray-700;
  display: flex;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 10px;
  align-items: center;

  i {
    margin-right: 8px;
  }
}

.amountWrapper {
  text-align: right;
  border-top: 1px solid $gray-200;
}

.amount {
  margin-bottom: 0;
}

.income {
  &::before {
    content: '+ ';
  }
}

.expense {
  &::before {
    content: '- ';
  }
}

.entry {
  margin: 8px 0;
  word-break: break-word;

  .label {
    font-size: 0.8rem;
  }
}
