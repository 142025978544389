@import 'assets/styles/responsive';
@import 'assets/styles/_variables.scss';

.container {
  width: 430px;
  position: fixed;
  min-height: 100vh;
  background: url('/assets/images/picture.jpg') no-repeat 64% 0 / cover;

  @include media-breakpoint-down(md) {
    min-height: 0;
    height: 156px;
    flex: 0 0 100%;
    position: static;
    background-image: url('/assets/images/polygon-tablet.png');
  }

  @include media-breakpoint-down(sm) {
    height: 64px;
    background: none;
    border-bottom: 1px $gray-600 solid;
  }
}

.polygon {
  padding: 22px 0 0 32px;

  @include media-breakpoint-up(md) {
    padding-top: 66px;
  }

  @include media-breakpoint-up(lg) {
    bottom: 0;
    width: 430px;
    height: 214px;
    position: fixed;
    padding: 100px 0 0 24px;
    background: url('/assets/images/polygon.png');
    background-size: cover;
  }
}

.polygon svg {
  width: 140px;
  fill: $primary;

  @include media-breakpoint-up(md) {
    width: 165px;
  }
}
