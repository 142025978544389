@import 'assets/styles/variables';
@import 'assets/styles/responsive';
@import 'app/shared/sidebar/sidebar.module';

.button {
  padding: 0;
  border: none;
  font-size: 14px;
  cursor: pointer;
  background: none;
  color: $secondary;
  text-decoration: none;
  outline: none !important;
}

.kebabMenu {
  height: 40px;
  width: 35px;
  fill: $gray-dark;

  &:active {
    fill: $secondary;
  }

  &:hover {
    fill: $secondary;
  }

  &:focus {
    fill: $secondary;
  }
}

.kebabMenuSelected {
  fill: $secondary;
}

.button:disabled {
  color: #555;
}

.withShadow {
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
}

.stickyHeader {
  transition: box-shadow $animation-translation-time ease-in-out;
  background: white;
  padding-top: 0px;
  @include media-breakpoint-up(md) {
    padding-top: 10px;
  }
}

.selectAllCheckbox {
  align-content: center;
  height: 30px;
  margin-top: 5px;
}

.headerInnerWrapper {
  flex-wrap: wrap;
}

h4 {
  font-size: 16px;
}

.headerTittleWrapper {
  height: 30px;
  margin-top: 17px;
}

.headerButtonWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
