@import 'assets/styles/responsive';

.title {
  display: none;
  font-weight: 400;
  margin: 24px 0 0;
  padding: 0 16px 0 24px;
  color: $sidebar-title-color;
  font-size: $sidebar-title-font-size;
}

:global(.sidebar-open) .title {
  display: block;
}
