.heading {
  display: flex;
  align-items: center;

  span {
    flex: 0 0 1rem;
  }

  h3 {
    margin-left: 16px;
  }
}
