@import 'assets/styles/variables';

.content {
  margin: 25px 0;
  align-items: center;
}

.minHeight {
  min-height: 200px;
}

.icon {
  font-size: 58px;
}

.iconSuccess {
  color: $success;
}

.iconFailure {
  color: $failure;
}
