@import 'assets/styles/variables';

.summaryField {
  margin: 0 0 32px;

  h3 {
    font-size: 12px;
    margin-bottom: 4px;
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
}
