@import 'assets/styles/variables';

.placeholder {
  width: 100%;
  height: 21px;
  border-radius: 100px;
  display: inline-block;
  vertical-align: middle;
  background: rgba($gray-600, 0.1);
  flex: 0 0 auto;
}

.light {
  background: rgba($gray-600, 0.05);
}

.green {
  background: rgba($green, 0.1);
}

.success {
  background: rgba($success, 0.1);
}

.purple {
  background: rgba($action-placeholder-bg, 0.1);
}

.primary {
  background: rgba($modal-title-color, 0.1);
}
