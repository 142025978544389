@import 'assets/styles/responsive';

.infoWrapper {
  display: flex;
  border: 1px solid $gray-200;
  border-radius: $border-radius;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
}

.invalidate {
  width: 100%;
}

.basicInfo {
  padding: 15px;
  max-width: 30%;
  background-color: $gray-100;

  @include media-breakpoint-down(sm) {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.balance {
  font-size: 1.25rem;
  font-weight: $font-weight-bold;
}

.additionalInfo {
  flex: 1;
  padding: 15px;

  @include media-breakpoint-down(sm) {
    flex-basis: 100%;
  }
}

.additionalInfo strong {
  color: $gray-900;
}

.equal {
  min-height: 3.5rem;
  margin-bottom: 0.5rem;
}

.row {
  width: 100%;
  display: flex;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.col {
  width: 50%;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

:global(.sidebar-open) {
  .row {
    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }

  .col {
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
}
