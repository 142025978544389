@import 'assets/styles/variables';
$spacing: 10px;

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-height: 100%;
  max-width: 28%;
  min-width: 300px;
  padding-right: $spacing;
  position: fixed;
  right: 0;
  top: $spacing;
  z-index: $z-index-notifications;
}

.wrapper:hover {
  & > * {
    opacity: 0.8;
  }

  & > *:hover {
    opacity: 1;
  }
}
