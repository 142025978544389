@import 'assets/styles/variables';

.label {
  margin-bottom: 0;
  position: relative;
  width: 100%;
  font-size: 0.75rem;

  display: inline-flex;
  justify-content: space-between;
}

.labelText {
}

.labelRequired {
  .labelText::after {
    color: $red;
    content: ' *';
  }
}

.labelDisabled {
  .labelText::after {
    color: $input-disabled-color;
  }
}
