@import 'assets/styles/responsive';
@import 'assets/styles/variables';

.item {
  padding: 10px;
  display: flex;
  flex-direction: row;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  &:nth-child(n + 2) {
    border-top: 1px solid $gray;
  }

  &:nth-child(odd) {
    background-color: $table-accent-bg;
  }

  &:nth-child(even) {
    background-color: $white;
  }

  > * {
    flex: 1;

    @include media-breakpoint-up(lg) {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}

.removeItem {
  max-width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-down(md) {
    margin-top: 5px;
  }
}

.addRow {
  margin-top: 30px;
}
