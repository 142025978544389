@import 'assets/styles/responsive';

.wrapper {
  padding: $card-padding / 2 $card-padding;
  background: $table-accent-bg;
  z-index: 8;
}

.submit {
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.title {
  font-size: 1rem;
  color: $secondary;
}

.custom-gutter {
  margin-right: -$card-padding/3;
  margin-left: -$card-padding/3;

  [class^='col-'] {
    padding-left: $card-padding/3;
    padding-right: $card-padding/3;
  }
}

.spacing {
  @include media-breakpoint-down(lg) {
    margin-top: $card-padding/2;
  }
}
