@import 'assets/styles/responsive';

.header {
  @include media-breakpoint-up(xl) {
    width: 50%;
  }
}

.info {
  padding: 0 24px;
}
