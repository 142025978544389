@import 'assets/styles/variables';

.terms {
  display: flex;
  align-items: center;
  font-size: 20px;
  min-height: initial;
  padding-right: 70px;
}

.link {
  color: $secondary;
  cursor: pointer;
}

.checkbox {
  margin-right: 10px;
}
