@import 'assets/styles/responsive';

.row {
  flex-wrap: nowrap;
}

.container {
  min-height: 100vh;
  padding: 0 0 158px 0;
  max-width: calc(100% - 72px);
  border-left: 1px rgba(#c8c8c8, 0.2) solid;

  // Prevent half-pixel columns
  border-right: 1px transparent solid;

  :global(.sidebar-open) & {
    max-width: calc(100% - #{$sidebar-width});
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 48px;
    max-width: 100%;
  }
}
