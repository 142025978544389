@import 'assets/styles/variables';

.button {
  width: 100%;
  position: relative;

  i {
    position: absolute;
    top: 50%;
    left: $card-spacer-x * 0.5;
    transform: translateY(-50%);
  }
}

.checkboxCol {
  width: 100px;
}

.buttonCol {
  width: 300px;
}

.row {
  height: 55px;
}
