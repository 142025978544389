@import 'assets/styles/variables';

$default-size: 16;

.loading {
  position: relative;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    width: $default-size * 1px;
    height: $default-size * 1px;
    display: block;
    border: 0.125rem solid $primary;
    animation: spin-around 500ms infinite linear;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
  }
}

.oveflow {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
}

.primary {
  &::after {
    border-color: $primary;
    border-right-color: transparent;
    border-top-color: transparent;
  }
}

.secondary {
  &::after {
    border-color: $secondary;
    border-right-color: transparent;
    border-top-color: transparent;
  }
}

.small {
  &::after {
    width: 1rem;
    height: 1rem;
  }
}

$medium-size: $default-size * 2px;
.medium {
  &::after {
    width: $medium-size;
    height: $medium-size;
  }
}

$large-size: $default-size * 3px;
.large {
  &::after {
    width: $large-size;
    height: $large-size;
  }
}

@keyframes spin-around {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
