@import 'assets/styles/variables';

.input[type='number'] {
  appearance: textfield;
}

.input[type='number']::-webkit-inner-spin-button,
.input[type='number']::-webkit-outer-spin-button,
.input[type='number']:hover::-webkit-inner-spin-button,
.input[type='number']:hover::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

//.large {
//  height: 48px;
//  line-height: 24px;
//  padding: 11px 16px;
//}

.button {
  padding: 3px;
}

.arrow::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 9px 8px 9px;
  border-color: transparent transparent $benefit-calc-bg transparent;
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%);
}

:global(.is-invalid) .arrow::after {
  bottom: -42px;
}

//
// Fix for IE11
//

:global(.form-control:-ms-input-placeholder) {
  color: $gray-300;
}
