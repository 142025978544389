.wrapper {
  margin-right: 3px;
  display: flex;
}

.month {
  min-width: 110px;
  padding-right: 8px;
}

.year {
  min-width: 110px;
  padding-right: 8px;
}
