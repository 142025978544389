.container {
  padding: 0;
  max-width: none;
}

.row {
  margin: 0;
}

.col {
  padding: 0;

  &:not(:first-child) {
    padding-left: 10px;
  }
}

.mediumSize {
  width: 50%;
  margin-left: 0;
  margin-right: 0;

  .horizontalLayoutItem:not(:first-child) {
    margin-left: 20px;
  }
}