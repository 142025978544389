@import 'assets/styles/variables';

.amount {
  white-space: pre;
  color: $green;

  &::before {
    content: '+';
  }
}

.table {
  td {
    width: 25%;
  }
}
