@import 'assets/styles/variables';

.pagination {
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: flex-end;
}

.input {
  width: 32px;
  height: 32px;
  appearance: none;
  text-align: center;
  border-radius: 4px;
  border: 1px $secondary solid;
  margin-left: 8px;
}

.total {
  margin: 0 8px;
  color: $gray-600;
}

.limit {
  width: 75px;

  $select-height: 32;
  :global(.Select-control) {
    height: ($select-height) * 1px;

    :global(.Select-value) {
      line-height: ($select-height - 2) * 1px;
    }

    :global(.Select-input) {
      line-height: ($select-height - 2) * 1px;
      height: ($select-height - 2) * 1px;
    }
  }

  :global(.Select-option) {
    padding: 4px 15px;
  }
}
