@import 'assets/styles/responsive';

.header {
  padding: 56px 12px 8px;
  min-height: 157px + $sidebar-title-font-size * 1.5;

  @include media-breakpoint-up(lg) {
    :global(.sidebar-open) & {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  @include media-breakpoint-down(xs) {
    :global(.sidebar-open) & {
      padding-top: 12px;
      min-height: 32px + $sidebar-title-font-size * 1.5;
    }
  }
}

.header ul {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}

.user {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.name {
  display: none;
  padding-left: 8px;
  color: $sidebar-user-color;

  :global(.sidebar-open) & {
    display: block;
  }
}

.hourglassIcon {
  fill: $red;
  padding-right: 4px;
  height: 15px;
  padding-top: 4px;
}

.businessModalContent {
  border: 1px solid $secondary;
  border-left: 0;
  border-right: 0;
  padding: 0;

  & .businessLabel {
    background: rgba($secondary, 0.1);
    padding: 2px 24px;
  }
}

.separator {
  border-top: 3px solid $gray-light;
  margin-left: -10px;
  padding: 0 10px;
}

.name p {
  margin-bottom: 0;
}

.title {
  margin-bottom: 2px;
  font-size: $table-head-font-size;
  color: $sidebar-user-heading-color;
}

.desktopTitle {
  display: none;
  margin-bottom: 4px;
  margin-left: 1px;
  font-weight: 400;
  color: $sidebar-title-color;
  font-size: $sidebar-title-font-size;
  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.profile {
  padding: 0;
  list-style: none;
  margin: 0 0 0 auto;
}

.profile li {
  margin-top: 0;
}

.profile a {
  :global(.sidebar-open) & {
    width: calc(100% - 16px) !important;
  }
}

.profile a i {
  font-size: 24px;
}

.icons {
  display: block;
  margin: 0 -16px;

  @include media-breakpoint-up(lg) {
    :global(.sidebar-open) & {
      display: none;
    }
  }
}

.icons a {
  padding: 8px 0;
}

.icons i {
  font-size: 24px;
  color: $sidebar-icon-color;
}

.buttons {
  display: none;

  @include media-breakpoint-up(lg) {
    :global(.sidebar-open) & {
      display: block;
    }
  }
}
